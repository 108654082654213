import { Autosuggest, AutosuggestProps, Box, Button, Hotspot, Link, Modal, RadioGroup, RadioGroupProps, SpaceBetween, TextContent, Toggle } from "@cloudscape-design/components";
import { useState, useEffect } from "react";
import '../styles/preferences-pane.scss';
import { usePreferences } from "./providers/PreferencesProvider";
import { useTranslate } from "src/i18n/useTranslate";
import { PreferenceLocation, PreferenceSelection } from "src/types/preferences";
import { HOT_SPOTS } from "src/tutorials/enums";
import { PREFERENCE_DISPLAYED_LANGUAGES} from "src/constants";

interface PreferencesPaneProps {
    preferencesOpen: boolean;
}

interface RadioGroupType {
    header: string;
    key: keyof PreferenceSelection;
    items: RadioGroupProps.RadioButtonDefinition[];
    value: string | null;
    showPlaceholder?: boolean;
    placeholderText?: string;
}

export default function PreferencesPane({
    preferencesOpen,
}: PreferencesPaneProps) {
  
    const t = useTranslate();
    const [autosuggestValue, setAutosuggestValue] = useState<string>('');
    const [toggleDisable, setToggleDisable] = useState<boolean>(false);

    const {
        updateRefinementToggleState,
        selectedRefinementToggleOn,
        userPreferences,
        preferenceDataLoaded,
        selectedUserPreferences,
        ssoDetectedPreferences,
        countries,
        regions,
        sites,
        languages,
        roles,
        isSaving,
        hasErrored,
        handleSelectionChange,
        canPreferencesSaved,
        resetPreferencesSelection,
        savePreferences
    } = usePreferences();

    useEffect(() => {
        preferenceDataLoaded && setToggleDisable(userPreferences?.language.id === 1);
    }, [preferenceDataLoaded, userPreferences?.language.id]);

    const autosuggestSites = sites.map(s => {
        return {
            value: `${s.id}`,
            label: s.name
        }
    });

    const filterSiteSuggestions = autosuggestSites.filter((site) => site.label.toLowerCase().includes(autosuggestValue.trim().toLowerCase()));


    const autosuggestSiteIds = sites.map(s => String(s.id));

    const mapToRadioGroupItems = (options: PreferenceLocation[], selectedId?: number): RadioGroupProps.RadioButtonDefinition[] => { 
        return (options || []).map(option => {
            if (selectedId) {
                return {
                    value: String(option.id),
                    label: option.id === selectedId ? `${option.name} - ${t('detected', 'Detected')}` : option.name, 
                    disabled: isSaving
                }
            }
            
            return {
                value: String(option.id),
                label: option.name,
                disabled: isSaving
            }
        })
    }

    const handleRadioSelection = (key: keyof PreferenceSelection, detailValue: string) => {
        handleSelectionChange(key, detailValue)
        if (key === "country" || key === "site") {
            setAutosuggestValue("");
        }
        if (key === "language") {
            if (detailValue === "1") {
                updateRefinementToggleState(false);
                setToggleDisable(true);
            } else {
                setToggleDisable(false);
            }
        }
    }

    const saveButtonText = t(isSaving ? 'user_preference_saving_preferences_loading_message' : 'save_button', isSaving ? 'Saving User Preferences' : 'Save');
    const displayed_languages = languages.map(language => ({
        ...language,
        name: PREFERENCE_DISPLAYED_LANGUAGES[language.id]
    }));
    const RadioGroups: RadioGroupType[] = [
        {
            header: t('user_preference_modal_region', 'Region'),
            key: 'region',
            value: selectedUserPreferences.region?.id?.toString() || null,
            items: mapToRadioGroupItems(regions, ssoDetectedPreferences.region?.id)
        },
        {
            header: t('user_preference_modal_country', 'Country'),
            key: 'country',
            value: selectedUserPreferences.country?.id?.toString() || null,
            items: mapToRadioGroupItems(countries, ssoDetectedPreferences.country?.id),
            showPlaceholder: !selectedUserPreferences.region?.id,
            placeholderText: t('preferences_select_country_placeholder', 'Please select a region first')
        },
        {
            header: t('user_preference_modal_site', 'Site'),
            key: 'site',
            value: selectedUserPreferences.site?.id?.toString() || null,
            items: mapToRadioGroupItems(sites),
            showPlaceholder: !selectedUserPreferences.country?.id,
            placeholderText: t('preferences_select_site_placeholder', 'Please select a country first')
        },
        {
            header: t('user_preference_select_langauage', 'Language'),
            key: 'language',
            value: selectedUserPreferences.language?.id?.toString() || null,
            items: mapToRadioGroupItems(displayed_languages)
        },
        {
            header: t('user_preference_select_role', 'Role'),
            key: 'role',
            value: selectedUserPreferences.role?.id?.toString() || '0',
            items: mapToRadioGroupItems(roles)
        },
    ];

    useEffect(() => {
        setAutosuggestValue("")
        setToggleDisable(userPreferences?.language.id === 1);
    }, [preferencesOpen]);

    return (
        <div>
            <Modal
                onDismiss={resetPreferencesSelection}
                visible={preferencesOpen}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween alignItems="center" direction="horizontal" size="xs">
                            <Toggle disabled={toggleDisable}
                                    onChange={({ detail }) =>
                                        updateRefinementToggleState(detail.checked)
                                    }
                                    checked={selectedRefinementToggleOn}
                                    data-testid="locale-toggle"
                                >
                                    {t('home_navigation_include_english_toggle_locale', "Include English (US)")}
                            </Toggle>
                            <Button variant="link" disabled={isSaving} onClick={resetPreferencesSelection}>{t('cancel_button', 'Cancel')}</Button>
                            <Hotspot hotspotId={HOT_SPOTS.PREFERENCES_SAVE_BUTTON} side="left" >
                                <Button variant="primary" loading={isSaving} disabled={!canPreferencesSaved()} data-testid="save-button" onClick={savePreferences}>{saveButtonText}</Button>
                            </Hotspot>
                        </SpaceBetween>
                    </Box>
                }
                header={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <h2>{t('user_preference_modal_heading', 'User Preferences')}</h2>
                            <TextContent><small data-preferences-subtitle>{t('user_preference_modal_sub_heading', 'Personalize your experience on GRU by selecting your Locale, Language, and Role')}</small></TextContent>
                        </SpaceBetween>
                    </Box>
                }
            >
                <div data-preferences-content-container>
                    {
                        RadioGroups.map(({ header, key, items, value, showPlaceholder, placeholderText }) => {
                            if (key !== "site") {
                                return (
                                    <div data-preferences-radio-container key={key}>
                                        <SpaceBetween size="xs">
                                            <Hotspot hotspotId={`preferences-${key}`} direction={key === 'role' ? 'left' : 'right'} side={key === 'role' ? 'left' : 'right'}>

                                                <TextContent><h4>{header}</h4></TextContent>
                                            </Hotspot>
                                            {!showPlaceholder ? (
                                                <RadioGroup
                                                    data-testid={`${key}-select`}
                                                    onChange={({ detail }) => handleRadioSelection(key, detail.value)}
                                                    value={value}
                                                    items={items}
                                                />
                                            ) : (
                                                <TextContent>
                                                    <small>{placeholderText}</small>
                                                </TextContent>
                                            )}
                                        </SpaceBetween>
                                    </div>
                                );
                            } else {
                                return (
                                    <div data-preferences-radio-container key={key}>
                                        <SpaceBetween size="xs">
                                            <Hotspot hotspotId={`preferences-${key}`} direction="right"><TextContent><h4>{header}</h4></TextContent></Hotspot>
                                            {!showPlaceholder ? (
                                                <SpaceBetween size="xs">
                                                    <Autosuggest
                                                        onChange={({ detail }) => {
                                                            setAutosuggestValue(detail.value);
                                                        }}
                                                        onSelect={({ detail }) => {
                                                            setAutosuggestValue(detail.selectedOption?.label || detail.value)
                                                            if(autosuggestSiteIds.includes(detail.value)){
                                                                handleSelectionChange(key, detail.value);
                                                            }
                                                        }}
                                                        data-testid="site-autosuggest"
                                                        value={autosuggestValue}
                                                        options={filterSiteSuggestions}
                                                        ariaLabel={t('search_for_a_site', 'Search for a site')}
                                                        placeholder={t('enter_site_name', 'Enter site name')}
                                                        empty={t('no_sites_found', 'No sites found')}
                                                        enteredTextLabel={() => !filterSiteSuggestions.length ? t('no_sites_found', 'No sites found'): ""}
                                                        filteringType="manual"
                                                        data-entered-text-label-show={autosuggestValue && filterSiteSuggestions.length ? "false" : "true"}
                                                    /> 
                                                    <RadioGroup
                                                        data-testid="site-select"
                                                        value={value}
                                                        items={items}
                                                        onChange={({ detail }) => {
                                                            handleSelectionChange(key, detail.value)

                                                            if (setAutosuggestValue) {
                                                                setAutosuggestValue(sites.find(r => `${r.id}` === detail.value)?.name as string || "");
                                                            }
                                                        }}
                                                    />
                                                </SpaceBetween>
                                            ) : (
                                                <TextContent>
                                                    <small>{placeholderText}</small>
                                                </TextContent>
                                            )}
                                        </SpaceBetween>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </Modal>
        </div>
    );

}