import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import FavoritesImage from "src/images/tutorial-favorites.jpg";

export const FavoritesScreen = () => {
  const t = useTranslate();
  return (
    <SpaceBetween data-testid="welcome-gru-favorites-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={FavoritesImage} height={300} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_favorites_description",
          "Save your most-used content to your <b>Favorites</b> for easy access in the future.",
          { b: (chunk: string) => <strong key={chunk}>{chunk}</strong> }
        )}
      </Box>
    </SpaceBetween>
  );
};
