import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import NavigationImage from "src/images/tutorial-navigation.jpg";

export const NavigationScreen = () => {
  const t = useTranslate();

  return (
    <SpaceBetween data-testid="welcome-gru-navigation-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={NavigationImage} height={300} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_nav_description",
          "Now that you've set your User Preferences, let's try to find the content you're looking for. Use the navigation to find specific content, and the breadcrumbs to return to other categories."
        )}
      </Box>
    </SpaceBetween>
  );
};
