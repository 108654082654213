import { useEffect, useState } from "react";
import SpaceBetween from "@cloudscape-design/components/space-between";
import get from "lodash/get";

import ExpandableContainer, { Item } from "./ExpandableContainer";
import { NavGraph, NavGraphItem } from "src/types/navGraph";
import { useNavigate } from "react-router";
import LoadingSpinner from "./LoadingSpinner";
import { useArtifactDetails } from "./providers/ArticleDetailsProvider";
import { SharedLinkInput } from "src/types/sharedLink";
import DynamicLayout from "./DynamicLayout";
import { useTranslate } from "src/i18n/useTranslate";
import { usePreferences } from "./providers/PreferencesProvider";
import { getFilteredNavGraph } from "src/utils/hierarchyUtils";
import { Box } from "@cloudscape-design/components";
import { useNavGraph } from "./providers/NavGraphProvider";
import { useRecentlyViewed } from "./providers/RecentlyViewedProvider";
import { openExternalUrl } from "src/helpers";

export interface FilterItem {
    title: string
    itemSelected: string | null
    items: Item[]
}

interface HierarchyFiltersProps {
    setExploreModalOpen?: (isOpen: boolean) => void;
    modalVersion?: boolean;
    filterToggleChecked: boolean;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}


const HierarchyFilters = ({ modalVersion = false, setExploreModalOpen, filterToggleChecked, setHasOpenedInNewTab, }: HierarchyFiltersProps) => {
    const t = useTranslate();

    const [filters, setFilters] = useState<FilterItem[]>([])

    const [showingDynamicLayout, setShowingDynamicLayout] = useState<boolean>(false)
    const [dynamicLayoutIsArtifact, setDynamicLayoutIsArtifact] = useState<boolean>(false)
    const [dynamicDrilldownOptions, setDynamicDrilldownOptions] = useState<FilterItem>()
    const [dynamicLayoutTitle, setDynamicLayoutTitle] = useState<string>()
    const [dynamicArtifactId, setDynamicArtifactId] = useState<string>()
    const [dynamicArtifactImage, setDynamicArtifactImage] = useState<string>()
    const [dynamicArtifactVersionId, setDynamicArtifactVersionId] = useState<string>()

    const {navGraph, isLoading, hasError } = useNavGraph();
    const { setArtifactDetails } = useArtifactDetails();
    const { addRecentlyViewed } = useRecentlyViewed();

    const { userPreferences } = usePreferences();

    const filteredGraph = structuredClone(navGraph) as NavGraph;
    getFilteredNavGraph(filteredGraph, userPreferences, filterToggleChecked)

    const navigate = useNavigate()

    const getItemByPath = (path: string) => {
        const pathArr = path.split('/').slice(1).filter(Boolean).join('-children-').split('-');
        return get(filteredGraph, pathArr) as NavGraphItem
    }

    const generateFilter = (navItem: NavGraphItem): FilterItem => {

        const childrenItems = Object.values(navItem.children || {})
            .sort((child1, child2) => {
                // find "Nav-Order-In-Card-Or-List" custom attribute
                const child1NavOrder = child1.customAttributes?.find((attr) => attr.id === 19)?.value.replace(/[^0-9.]/g, '') || undefined;
                const child2NavOrder = child2.customAttributes?.find((attr) => attr.id === 19)?.value.replace(/[^0-9.]/g, '') || undefined;
                
                if (child1NavOrder && child2NavOrder) {
                    if (child1NavOrder === child2NavOrder) return child1.name.localeCompare(child2.name);
                    return parseInt(child1NavOrder) < parseInt(child2NavOrder) ? -1 : 1;
                } else if (child1NavOrder || child2NavOrder) {
                    return child1NavOrder ? -1 : 1;
                }
                return child1.name.localeCompare(child2.name)
            })
            .map(item => {
                return {
                    title: item.name,
                    path: item.path,
                    dateUpdated: item.dateUpdated || "",
                    description: item.description || "",
                    image: item.pictureUrl || "",
                    isDynamic: item.isDynamic,
                }
            });

        const documentItems = Object.values(navItem.documents || {})
            .sort((doc1, doc2) => {
                // find "Nav-Order-In-Card-Or-List" custom attribute
                const doc1NavOrder = doc1.customAttributes?.find((attr) => attr.id === 19)?.value.replace(/[^0-9.]/g, '') || undefined;
                const doc2NavOrder = doc2.customAttributes?.find((attr) => attr.id === 19)?.value.replace(/[^0-9.]/g, '') || undefined;
                
                if (doc1NavOrder && doc2NavOrder) {
                    if (doc1NavOrder === doc2NavOrder) return doc1.name.localeCompare(doc2.name);
                    return parseInt(doc1NavOrder) < parseInt(doc2NavOrder) ? -1 : 1;
                } else if (doc1NavOrder || doc2NavOrder) {
                    return doc1NavOrder ? -1 : 1;
                }
                return doc1.name.localeCompare(doc2.name)
            })
            .map(document => {
                return {
                    title: document.latestVersion.fileName,
                    documentId: document.id,
                    versionId: document.latestVersion.id,
                    dateUpdated: document.dateUpdated || "",
                    externalLinkUrl: document.externalLinkUrl,
                    description: document.description || "",
                    image: document.pictureUrl || "",
                    isDynamic: document.isDynamic,
                }
            });

        return {
            title: navItem.name,
            itemSelected: null,
            items: [...childrenItems, ...documentItems]
        }
    }

    const handleItemSelection = ({ path, documentId, versionId, externalLinkUrl, isDynamic, dateUpdated, title }: SharedLinkInput) => {
        if (!isDynamic) {
            setShowingDynamicLayout(false);
        }

        if (externalLinkUrl?.length) {
            handleExternalLinkSelection(externalLinkUrl);
            documentId && addRecentlyViewed(documentId);
        } else if (isDynamic) {
            handleDynamicSelection({ documentId, versionId, path });
        } else if (documentId) {
            handleArtifactSelection({ path, documentId, externalLinkUrl, isDynamic, dateUpdated, title });
        } else if (path?.length) {
            handleDrillDownNavigation(path);
        }
    }

    const handleExternalLinkSelection = (externalLinkUrl: string) => {
        openExternalUrl(externalLinkUrl);
    };

    const handleDynamicSelection = ({ documentId, versionId, path, title }: { documentId?: number, versionId?: number; path?: string; title?: string }) => {
        setShowingDynamicLayout(true);

        const prevFilters = [...filters]
        prevFilters[prevFilters.length - 1].itemSelected = null;
        const lastFilter = filters.find(f => !f.itemSelected);
        const selectedItem = lastFilter!.items.find((s: Item) => s.documentId == documentId);

        const name = path?.length ? generateFilter(getItemByPath(path)).title.split("Select ")[1] : selectedItem?.title;
        prevFilters[prevFilters.length - 1].itemSelected = name as string || null;
        setFilters(prevFilters)

        if (documentId) {
            // handle dynamic artifact selection here
            setDynamicLayoutIsArtifact(true);
            setDynamicLayoutTitle(title || selectedItem?.title);
            setDynamicArtifactId(`${documentId}`)
            setDynamicArtifactVersionId(String(versionId));
        } else if (path?.length) {
            // handle dynamic nav drilldown selection here
            const navItem = getItemByPath(path);
            const options = generateFilter(navItem);
            setDynamicDrilldownOptions(options)
            setDynamicLayoutIsArtifact(false);
            setDynamicLayoutTitle(navItem.name);
            setDynamicArtifactImage(navItem.pictureUrl)
            setDynamicArtifactId('');
            setDynamicArtifactVersionId('');
        }
    }

    const handleDrillDownNavigation = (path: string) => {
        const prevFilters = [...filters]
        const navItem = getItemByPath(path);
        prevFilters[prevFilters.length - 1].itemSelected = navItem.name;
        prevFilters.push(generateFilter(navItem))
        setFilters(prevFilters)
    }

    const handleArtifactSelection = (documentInfo: SharedLinkInput) => {
        if (setArtifactDetails) {
            setArtifactDetails(filters, documentInfo);
        }

        setHasOpenedInNewTab(false);
        navigate(`/${documentInfo.documentId}`)

        if (modalVersion && setExploreModalOpen) {
            setExploreModalOpen(false);
        }
    }

    const handleExpandChange = (index: number) => {
        setShowingDynamicLayout(false);
        if (index !== filters.length - 1) {
            const prevFilters = [...filters].slice(0, index + 1)
            prevFilters[index].itemSelected = null

            setFilters(prevFilters)
        }
    }

    const createInitialFilter = () => {
        const children = Object.values(filteredGraph);
        children.sort((child1, child2) => {
            // find "Nav-Order-In-Card-Or-List" custom attribute
            const child1NavOrder = child1.customAttributes?.find((attr) => attr.id === 19)?.value.replace(/[^0-9.]/g, '') || undefined;
            const child2NavOrder = child2.customAttributes?.find((attr) => attr.id === 19)?.value.replace(/[^0-9.]/g, '') || undefined;
            
            if (child1NavOrder && child2NavOrder) {
                if (child1NavOrder === child2NavOrder) return child1.name.localeCompare(child2.name);
                return parseInt(child1NavOrder) < parseInt(child2NavOrder) ? -1 : 1;
            } else if (child1NavOrder || child2NavOrder) {
                return child1NavOrder ? -1 : 1;
            }
            return child1.name.localeCompare(child2.name)
        });

        setFilters([
            {
                title: children.map(child => child.name).join(' / '),
                itemSelected: null,
                items: children.map(child => {
                    return {
                        title: child?.name,
                        path: child?.path,
                        description: child?.description || "",
                        image: child?.pictureUrl || ""
                    }
                })
            }
        ])
    }

    useEffect(() => {
        if (filteredGraph) {
            setShowingDynamicLayout(false);
            createInitialFilter();
        }
    }, [navGraph, filterToggleChecked, userPreferences])

    if (isLoading) return <LoadingSpinner message={`${t('navigation_loading_message', 'Loading Navigation Filters')}...`} />;
    if (hasError || !filters.length) return null;

    const headerTextPrefix = t('navigation_select', 'Select');

    return filterToggleChecked && !Object.keys(filteredGraph || {}).length ?
        <Box variant="h3" textAlign="center" >{t('navigation_empty', "Your current user preference filters aren't returning any document results. Try adjusting your role, language, or region.")}</Box>
        : (
            <SpaceBetween size="m">
                {filters.map((filter, index) => <ExpandableContainer
                    key={index}
                    items={filter.items}
                    headerText={headerTextPrefix + " " + filter.title}
                    expanded={index === filters.length - 1 && !showingDynamicLayout}
                    selection={filter.itemSelected}
                    handleExpandChange={() => handleExpandChange(index)}
                    handleItemSelection={handleItemSelection}
                />)}
                {showingDynamicLayout && (
                    <DynamicLayout
                        title={dynamicLayoutTitle}
                        dynamicDrilldownOptions={dynamicDrilldownOptions}
                        dynamicLayoutIsArtifact={dynamicLayoutIsArtifact}
                        dynamicArtifactImage={dynamicArtifactImage}
                        artifactId={dynamicArtifactId}
                        artifactVersionId={dynamicArtifactVersionId}
                        filters={filters}
                        modalVersion={modalVersion}
                        setExploreModalOpen={setExploreModalOpen}
                        setHasOpenedInNewTab={setHasOpenedInNewTab}
                        handleDynamicItemSelection={handleDynamicSelection}
                    />
                )}
            </SpaceBetween>
        )

}

export default HierarchyFilters;