import { Box, Button, Grid, Modal, SpaceBetween } from '@cloudscape-design/components';
import { useTranslate } from 'src/i18n/useTranslate';
import { useWelcomeModal } from './providers/WelcomeModalProvider';
import UserPreferencesPaneGIF from "src/images/user-pane.gif";

interface PreferencesModalProps {
    setUserPreferencesPaneOpen: (open: boolean) => void;
    overwriteModalOpen?: boolean;
}

export const PreferencesModal = ({ setUserPreferencesPaneOpen, overwriteModalOpen }: PreferencesModalProps) => {
    const t = useTranslate();
    const { preferencesModalOpen, setPreferencesModalOpen } = useWelcomeModal();

    const handleLaunch = () => {
        setPreferencesModalOpen(false); 
        setUserPreferencesPaneOpen(true);
    }

    return (
        <Modal
            onDismiss={() => setPreferencesModalOpen(false)}
            visible={overwriteModalOpen ||  preferencesModalOpen}
            size='large'
            data-testid='preferences-modal'
        >
            <SpaceBetween size='l'>
                <SpaceBetween data-testid="welcome-gru-quick-search-screen" size="l">
                    <div className="flex-row justify-content-center"><img src={UserPreferencesPaneGIF} height={300} /></div>
                    <Box variant="h3" >{t("preferences_modal_header", "Hey there! Have you updated your user preferences recently?")}</Box>
                    <Box variant="p" >{t("preferences_modal_description", "Review your preferences to ensure the experience is tailored to your current needs.")}</Box>
                </SpaceBetween>
                <Grid
                    gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
                >
                    <div className="display-none" />
                    <div className='flex-row justify-content-center' >
                        <Box variant='p' >{t("welcome_gru_page_count", `1 out of 1`, { currentPage: 1, pageTotal: 1 })}</Box>
                    </div>
                    <div className='flex-row justify-content-center' >
                        <Button data-testid="preferences-launch-button" variant='primary' onClick={handleLaunch}>{t("preferences_modal_launch_button", "Launch now")}</Button>
                    </div>
                </Grid>
            </SpaceBetween>
        </Modal>
    );
}