import { FlashbarProps, Link, TextContent } from "@cloudscape-design/components";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useTranslate } from "src/i18n/useTranslate";
import { useAuth } from "./AuthProvider";

export enum NOTIFICATIONS {
    PREFERENCES_ERROR = "preferences-error",
    FEEDBACK_ERROR = "feedback-error",
    SEARCH_ERROR = "search-error",
    FEEDBACK_SUCCESS = "feedback-success",
    ARTIFACT_ROLE_WARNING = "artifact-role-warning",
    WELCOME_GRU_INFO = "welcome-gru-info",
}

interface NotificationsContextType {
   notifications: FlashbarProps.MessageDefinition[];
   publishNotification: (props: PublishNotificationProps) => void;
   dismissNotification: (id: string) => void;
}

interface PublishNotificationProps {
    id: NOTIFICATIONS;
    content: React.ReactNode;
    type: FlashbarProps.Type;
}


export const NotificationsContext = React.createContext<NotificationsContextType>({
    notifications: [],
    publishNotification: (props: PublishNotificationProps) => {},
    dismissNotification: (id: string) => {}
})

export const useNotifications = () => useContext(NotificationsContext)

interface NotificationsProviderProps {
    children: React.ReactNode;
}

export const NotificationsProvider = ({ children }: NotificationsProviderProps) => {

    const [ notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);

    const t = useTranslate();
    const { pathname } = useLocation();

    useEffect(() => {
        setNotifications([]);
    }, [pathname])

    const dismissNotification = (id: string) => {
        setNotifications(notifications.filter(notification => notification.id !== id))
    }

    const publishNotification = ({id, content, type}: PublishNotificationProps) => {
        setNotifications([ ...notifications, {
            id: String(id),
            content,
            type,
            dismissible: true,
            dismissLabel: t('dismiss_preferences_error', "Dismiss this message"),
            onDismiss: () => dismissNotification(String(id))
        }])
    }


    const value: NotificationsContextType = {
        notifications,
        publishNotification,
        dismissNotification
    }

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
}