import React from "react";
import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";

export const getHelpContent = (key: string = 'default') => {
    const t = useTranslate();

    const contents: { [key: string]: React.ReactNode } = {
        "default": (
            <SpaceBetween size="l">
                <Box
                    fontWeight="bold"
                    color="text-body-secondary"
                    textAlign="center"
                    margin={{ top: 'xxl', horizontal: 'xxl' }}
                    fontSize="body-s"
                >
                    {t("help-panel-default-line-1", "No help content to show")}
                </Box>
                <Box color="text-body-secondary" textAlign="center" margin={{ horizontal: 'xxl' }} fontSize="body-s">
                    {t("help-panel-default-line-2", "There is no additional help content on this page.")}
                </Box>
            </SpaceBetween>
        )
    };

    return contents[key] || contents.default;
};