import { Box, Button, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import WikiImage from "src/images/tutorial-wiki.jpg";

export const WikiScreen = () => {
  const t = useTranslate();
  return (
    <SpaceBetween data-testid="welcome-gru-wiki-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={WikiImage} height={300} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_wiki_description",
          "Thanks for taking the learn more about GRU. For more information, see our detailed <wiki-link>Wiki</wiki-link> which provides additional information about GRU, including a feature list and FAQ.",
          { "wiki-link": (chunk: string) => <Button href="https://w.amazon.com/bin/view/Amazon_Robotics/BOS/LExT/GRU" target="_blank" variant="inline-link" key={chunk}>{chunk}</Button> }
        )}
      </Box>
    </SpaceBetween>
  );
};
