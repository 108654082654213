import { PreferenceLocation } from "./types/preferences";

export const DEFAULT_LOCALE = "en-US";

export const ENGLISH_LANGUAGE_NAME = "English (US)";

export const GRU_MESSAGE_BUNDLE_NAME = "pages.gru";

export const HOTKEY_SHORTCUT_LABEL = 'Shift +';

export const PREVIOUS_HOTKEY_SYMBOL = '\u25C4';

export const NEXT_HOTKEY_SYMBOL = '\u25BA';

export const LANGUAGES_TO_COUNTRY: {[key: string]: string} = {
	"ar": "sa",
    "cs": "cz",
    "en-us": "us",
    "fr-ca": "ca",
    "fr": "fr",
    "de": "de",
    "it": "it",
    "ja": "jp",
    "pl": "pl",
    "es": "es"
}

export const LANGUAGES_TO_LOCALE: {[key: string]: string} = {
	"ar": "ar-SA",
    "cs": "cs-CZ",
    "en-us": "en-US",
    "fr-ca": "fr-CA",
    "fr": "fr-FR",
    "de": "de-DE", 
    "it": "it-IT",
    "ja": "ja-JP",
    "pl": "pl-PL",
    "es": "es-ES"
}

export const PREFERENCE_DISPLAYED_LANGUAGES: {[key: number]: string} = {
	9: "Arabic (ar)",
    0: "Czech (cs)",
    1: "English (US) (en)",
    2: "French (Canada) (fr-CA)",
    3: "French (France) (fr-FR)",
    4: "German (de)", 
    5: "Italian (it)",
    6: "Japanese (ja)",
    7: "Polish (pl)",
    8: "Spanish (Spain) (es)"
}

export const COGNITO_COUNTRY_TO_REGION_PREFERENCE: {[key: string]: PreferenceLocation} = {
    "USA": {
        id: 198,
        name: 'North America (NA)',
        path: '187/198/',
    },
    "CAN": {
        id: 198,
        name: 'North America (NA)',
        path: '187/198/',
    },
    "CZE": {
		id: 264,
		path: '187/264/',
		name: 'Europe (EU)'
	},
    "FRA": {
		id: 264,
		path: '187/264/',
		name: 'Europe (EU)'
	},
    "DEU": {
		id: 264,
		path: '187/264/',
		name: 'Europe (EU)'
	},
    "ITA": {
		id: 264,
		path: '187/264/',
		name: 'Europe (EU)'
	},
    "POL": {
		id: 264,
		path: '187/264/',
		name: 'Europe (EU)'
	},
    "ESP": {
		id: 264,
		path: '187/264/',
		name: 'Europe (EU)'
	},
    "GBR": {
		id: 264,
		path: '187/264/',
		name: 'Europe (EU)'
	},
    "AUS": {
		id: 328,
		path: '187/328/',
		name: 'Asia Pacific (APAC)'
	},
    "JPN": {
		id: 328,
		path: '187/328/',
		name: 'Asia Pacific (APAC)'
	},
}

export const COGNITO_COUNTRY_TO_COUNTRY_PREFERENCE: {[key: string]: PreferenceLocation} = {
    "USA": {
		id: 802,
		path: "187/198/802/",
		name: "United States (US)"
	},
    "CAN": {
		id: 260,
		path: "187/198/260/",
		name: "Canada (CA)"
	},
    "CZE": {
		id: 265,
		path: "187/264/265/",
		name: "Czech Republic (CZ)"
	},
    "FRA": {
		id: 267,
		path: "187/264/267/",
		name: "France (FR)"
	},
    "DEU": {
		id: 271,
		path: "187/264/271/",
		name: "Germany (DE)"
	},
    "ITA": {
		id: 286,
		path: "187/264/286/",
		name: "Italy (IT)"
	},
    "POL": {
		id: 294,
		path: "187/264/294/",
		name: "Poland (PL)"
	},
    "ESP": {
		id: 299,
		path: "187/264/299/",
		name: "Spain (ES)"
	},
    "GBR": {
		id: 306,
		path: "187/264/306/",
		name: "United Kingdom (UK)"
	},
    "AUS": {
		id: 329,
		path: "187/328/329/",
		name: "Australia (AU)"
	},
    "JPN": {
		id: 331,
		path: "187/328/331/",
		name: "Japan (JP)"
	}
}
