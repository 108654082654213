import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import SearchResultsImage from "src/images/tutorial-search-results.jpg";

export const SearchResultsScreen = () => {
  const t = useTranslate();

  return (
    <SpaceBetween data-testid="welcome-gru-search-results-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={SearchResultsImage} height={300} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_search_results_description",
          "You can also search for your entered text by pressing enter or selecting Search. Filters on the left of the search results allow you to filter results in specific categories."
        )}
      </Box>
    </SpaceBetween>
  );
};
