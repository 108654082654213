import { Box, Button, Link, Modal, SpaceBetween, TextContent, Textarea } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import '../styles/feedback-modal.scss';
import { useTranslate } from "src/i18n/useTranslate";
import { useApi } from "src/hooks/useApi";
import { SaveFeedbackApiType } from "src/types/useApi";
import initialMetricsPublisher from "src/metrics";
import * as KatalMetrics from '@amzn/katal-metrics';
import { NOTIFICATIONS, useNotifications } from "./providers/NotificationsProvider";

interface FeedbackModalProps {
    feedbackModalOpen: boolean;
    setFeedbackModalOpen: (open: boolean) => void;
    topicId: string;
    topicTitle?: string;
    artifactId: string;
    artifactTitle: string;
}

export default function FeedbackModal({
    feedbackModalOpen,
    setFeedbackModalOpen,
    topicId,
    topicTitle = "",
    artifactTitle = "",
    artifactId
}: FeedbackModalProps) {
    const t = useTranslate();
    const [selectedStarAmount, setSelectedStarAmount] = useState<number>(0);
    const [hoveredStarAmount, setHoveredStarAmount] = useState<number>(0);
    const [feedbackTextContent, setFeedbackTextContent] = useState<string>('');

    const { publishNotification } = useNotifications();

    const saveFeedbackErrorMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('SaveFeedbackError');

    const { 
        makeRequest: makeSaveFeedbackRequest, 
        data: saveFeedbackResponse,
        error: saveFeedbackRequestError, 
        loading: saveFeedbackRequestLoading 
    }: SaveFeedbackApiType = useApi({
        method: 'POST',
        url: "/feedback"
    })

    const resetFeedbackInputs = () => {
        setSelectedStarAmount(0);
        setHoveredStarAmount(0);
        setFeedbackTextContent('');
        setFeedbackModalOpen(false); 
    }

    const canSave = (): boolean => {
        return selectedStarAmount > 0 || !!feedbackTextContent?.trim();
    }

    const submitFeedback = async () => {
        if(canSave()) {
            const feedbackData = { 
                rating: selectedStarAmount > 0 ? selectedStarAmount : undefined,
                feedback: feedbackTextContent || undefined,
                pageUrl: window.location.href,
                topicId: topicId,
                artifactId,
            } 

            if(selectedStarAmount <= 0) {
                delete feedbackData.rating;
            }

            if(!feedbackTextContent.trim()) {
                delete feedbackData.feedback;
            }

            makeSaveFeedbackRequest({ 
                data: feedbackData 
            })
            
            resetFeedbackInputs();
        }
    }

    useEffect(() => {
        if (saveFeedbackRequestError) {
            saveFeedbackErrorMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.error.errorType', 'Save Feedback'))
            publishNotification({
                type: "error",
                content: (
                    <>
                        {t('feedback_error', "Your feedback could not be collected at this time. Please try again later")}
                    </>
                ),
                id: NOTIFICATIONS.FEEDBACK_ERROR
            })
        }
    }, [saveFeedbackRequestError]);

    useEffect(() => {
        if (saveFeedbackResponse && saveFeedbackResponse?.status === 201) {
            publishNotification({
                type: "success",
                content: (
                    <>
                        {t('feedback_success', "Thank you for providing feedback!")}
                    </>
                ),
                id: NOTIFICATIONS.FEEDBACK_SUCCESS
            })
        }
    }, [saveFeedbackResponse]);

    useEffect(() => {
        resetFeedbackInputs();
    }, [artifactId, topicId]);

    const starIconButton = (iconNumber: number) => {
        return (
            <div 
                onMouseEnter={() => setHoveredStarAmount(iconNumber)}
                onMouseLeave={() => setHoveredStarAmount(0)}
            >
                <Button
                    data-testid={`feedback-${iconNumber}-star-button`}
                    data-feedback-star-button={
                        selectedStarAmount >= iconNumber ? "filled" : 
                        hoveredStarAmount >= iconNumber ? "hovered" : 
                        "empty"
                    }
                    iconName={selectedStarAmount >= iconNumber || hoveredStarAmount >= iconNumber ? "star-filled" : "star"}
                    variant="icon"
                    iconAlt={t(`feedback_${iconNumber}_star`, `Rate Article ${iconNumber} Stars`)}
                    onClick={() => selectedStarAmount === iconNumber ? setSelectedStarAmount(0) : setSelectedStarAmount(iconNumber)}
                />
            </div>
        )
    }

    return (
        <div data-feedback-modal>
            <Modal
                data-testid="feedback-modal"
                onDismiss={({detail}) => {
                    detail.reason === "closeButton" ? setFeedbackModalOpen(false) : resetFeedbackInputs();
                }}
                visible={feedbackModalOpen}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button 
                                variant="link" 
                                onClick={resetFeedbackInputs} 
                                ariaLabel={t('cancel_button', 'Cancel')}
                            >
                                {t('cancel_button', 'Cancel')}
                            </Button>
                            <Button 
                                variant="primary" 
                                loading={saveFeedbackRequestLoading} 
                                disabled={!canSave()} 
                                data-testid="feedback-save-button" 
                                onClick={submitFeedback} 
                                ariaLabel={t('submit', 'Submit')}
                            >
                                {t('submit', 'Submit')}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <h2>{t('feedback_modal_heading', 'How would you rate this page?')}</h2>
                        </SpaceBetween>
                    </Box>
                }
            >
                <div data-feedback-modal-content-container>
                    <SpaceBetween size="l">
                        <SpaceBetween size="s">
                            <h4 data-feedback-section-title>{t('page_link', 'Page link')}</h4>
                            <Link onFollow={() => setFeedbackModalOpen(false)}>{window.location.href}</Link>
                        </SpaceBetween>

                        <SpaceBetween size="s">
                            <h4 data-feedback-section-title>{t('feedback_artifact_title', 'Artifact title')}</h4>
                            <p data-feedback-section-content>{artifactTitle}</p>
                        </SpaceBetween>

                        <SpaceBetween size="s">
                            <h4 data-feedback-section-title>{t('page_title', 'Topic title')}</h4>
                            <p data-feedback-section-content>{topicTitle}</p>
                        </SpaceBetween>

                        <SpaceBetween size="s">
                            <h4 data-feedback-section-title>{t('rating', 'Rating')}</h4>
                            <TextContent>
                                <small>{t('on_a_scale', 'On a scale of 1-5 (1 = Poor, 5 = Excellent), please rate your overall experience with this page. Consider the clarity and structure of the presented information')}</small>
                            </TextContent>
                            <SpaceBetween size="xs" direction="horizontal">
                                {starIconButton(1)}
                                {starIconButton(2)}
                                {starIconButton(3)}
                                {starIconButton(4)}
                                {starIconButton(5)}
                            </SpaceBetween>
                        </SpaceBetween>

                        <SpaceBetween size="s">
                            <h4 data-feedback-section-title>{t('corresponding_feedback', 'Please let us know how this page can be improved to better meet your needs, or what you found most helpful')}</h4>
                            <Textarea
                                data-testid="feedback-textarea"
                                onChange={({ detail }) => setFeedbackTextContent(detail.value)}
                                value={feedbackTextContent}
                                placeholder={t('enter_feedback_here', "You may enter your feedback here...")}
                            />
                            <TextContent>
                                <small>{t('feedback_innacuracy', 'Note: To report content inaccuracies or technical issues, please submit a SIM using the')} <a href="https://river.amazon.com/VMO/home?buildingType=ARLExT&q0=77fcbc1f-2d31-4141-af4f-aa0cc8727df1" target="_blank">LExT River form.</a>{t('feedback_facilitate', 'This will facilitate faster resolution')}</small>
                            </TextContent>
                        </SpaceBetween>
                    </SpaceBetween>
                </div>
            </Modal>
        </div>
    );
}