import React from "react";
import SpaceBetween, { SpaceBetweenProps } from "@cloudscape-design/components/space-between";
import Spinner, { SpinnerProps } from "@cloudscape-design/components/spinner";
import Box from '@cloudscape-design/components/box';
import { useTranslate } from "src/i18n/useTranslate";

interface LoadingSpinnerProps {
    message?: string
    spinnerSize?: SpinnerProps.Size;
    alignment?: SpaceBetweenProps.Direction
}

const LoadingSpinner = ({ message, alignment = "vertical", spinnerSize = "large" }: LoadingSpinnerProps) => {
    const t = useTranslate();
    return (
        <SpaceBetween size="l" direction={alignment} data-testid="loading-spinner">
            <Box textAlign="center" ><Spinner size={spinnerSize} /></Box>
            <Box variant="h3" textAlign="center" >{message || `${t('loading', 'Loading')}...`}</Box>
        </SpaceBetween>
    )
}

export default LoadingSpinner;