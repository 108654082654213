
import React from 'react';
import { useTranslate } from 'src/i18n/useTranslate';
import { AppLayoutWrapper } from './AppLayoutWrapper';
import { ArtifactsList } from 'src/components/ArtifactsList';
import { useRecentlyViewed } from 'src/components/providers/RecentlyViewedProvider';

const RecentlyViewed = () => {
    const t = useTranslate();
    const { recentlyViewed } = useRecentlyViewed();

    const content = (
        <ArtifactsList
        numberOfItemsPerPage={12}
        name={t('landing_page_recently_view', 'Recently Viewed')}
        emptyText={t("recently_viewed_empty", "You don't have any recently viewed artifacts.")}
        artifacts={recentlyViewed.map(recent => {
            return {
                id: recent.id,
                ...recent.cardDetails!
            }
        })}
        />
    );

    return <AppLayoutWrapper content={content} />
};

export default RecentlyViewed;