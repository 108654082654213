import React, { useContext, useEffect, useState } from "react";
import { useApi } from "src/hooks/useApi";
import { PreferenceLocation, PreferenceSelection } from "src/types/preferences";
import { PostPreferencesSelectionApiType, PreferencesApiType, PreferencesSelectionApiType } from "src/types/useApi";
import { useLocalizationContext } from '@amzn/react-arb-tools'
import { isEqual } from "lodash";
import { isMobile } from 'react-device-detect';
import { useTranslate } from "src/i18n/useTranslate";
import { COGNITO_COUNTRY_TO_COUNTRY_PREFERENCE, COGNITO_COUNTRY_TO_REGION_PREFERENCE } from "src/constants";
import { DEFAULT_LOCALE, LANGUAGES_TO_LOCALE } from "src/constants";
import { useAuth } from "./AuthProvider";
import initialMetricsPublisher from "src/metrics";
import * as KatalMetrics from '@amzn/katal-metrics';
import { NOTIFICATIONS, useNotifications } from "./NotificationsProvider";
import { localizationContextBuilder } from ".";

export interface PreferencesContextType {
    userPreferences: PreferenceSelection;
    ssoDetectedPreferences: Partial<PreferenceSelection>;
    selectedUserPreferences: PreferenceSelection;
    regions: PreferenceLocation[];
    countries: PreferenceLocation[];
    sites: PreferenceLocation[];
    roles: PreferenceLocation[];
    languages: PreferenceLocation[];
    preferenceDataLoaded: boolean;
    isSaving: boolean;
    hasErrored: boolean;
    refinementToggleOn: boolean;
    selectedRefinementToggleOn: boolean;
    listViewStyle: string;
    canPreferencesSaved: () => boolean;
    handleSelectionChange: (key: keyof PreferenceSelection, id: string) => void;
    resetPreferencesSelection: () => void;
    savePreferences: () => void;
    updateRefinementToggleState: (value: boolean) => void;
    updateListViewStyleState: (value: string) => void;
}

const languages: PreferenceLocation[] = [
    { id: 9, path: "ar", name: "Arabic" },
    { id: 0, path: "cs", name: "Czech" },
    { id: 1, path: "en-us", name: "English (US)" },
    { id: 2, path: "fr-ca", name: "French (Canada)" },
    { id: 3, path: "fr", name: "French (France)" },
    { id: 4, path: "de", name: "German" },
    { id: 5, path: "it", name: "Italian" },
    { id: 6, path: "ja", name: "Japanese" },
    { id: 7, path: "pl", name: "Polish" },
    { id: 8, path: "es", name: "Spanish" },
];

export const preferencesDefault: PreferenceSelection = {
    language: {
        id: 1,
        path: "en-us",
        name: "English (US)"
    },
    role: {
        id: 0,
        path: "0/",
        name: "All Roles"
    },
    site: {
        id: 0,
        path: "0/",
        name: "All sites"
    },
    country: {
        id: 802,
        path: "187/198/802/",
        name: "United States (US)"
    },
    region: {
        id: 198,
        path: "187/198/",
        name: "North America (NA)"
    }
};


export const PreferencesContext = React.createContext<PreferencesContextType>({
    userPreferences: preferencesDefault,
    selectedUserPreferences: preferencesDefault,
    ssoDetectedPreferences: {},
    regions: [],
    countries: [],
    sites: [],
    roles: [],
    languages,
    preferenceDataLoaded: false,
    isSaving: false,
    hasErrored: false,
    refinementToggleOn: true,
    selectedRefinementToggleOn: true,
    listViewStyle: isMobile ? "grid-view" : "list-view",
    canPreferencesSaved: () => false,
    handleSelectionChange: () => { },
    resetPreferencesSelection: () => { },
    savePreferences: () => { },
    updateRefinementToggleState: () => { },
    updateListViewStyleState: () => {},
})

export const usePreferences = () => useContext(PreferencesContext)

interface PreferencesProviderProps {
    children: React.ReactNode;
    setPreferencesOpen: (isOpen: boolean) => void;
}

export const PreferencesProvider = ({ children, setPreferencesOpen }: PreferencesProviderProps) => {
    const t = useTranslate();
    const [preferenceDataLoaded, setPreferenceDataLoaded] = useState<boolean>(false);
    const [selectedUserPreferences, setSelectedUserPreferences] = useState<PreferenceSelection>(preferencesDefault);
    const [ssoDetectedPreferences, setSsoDetectedPreferences] = useState<Partial<PreferenceSelection>>({});
    const [sites, setSites] = useState<PreferenceLocation[]>([]);
    const [countries, setCountries] = useState<PreferenceLocation[]>([]);
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [refinementToggleOn, setRefinementToggleOn] = useState(true);
    const [selectedRefinementToggleOn, setSelectedRefinementToggleOn] = useState(true);
    const [listViewStyle, setListViewStyle] = useState(isMobile ? "grid-view" : "list-view");

    const { setLocalizationContext } = useLocalizationContext();
    const { user, isPrivacyRestricted } = useAuth();
    const { publishNotification } = useNotifications();

    const saveUserPreferenceErrorMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('SaveUserPreferenceError');
    const saveUserPreferenceMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('SaveUserPreference');

    const { makeRequest: getAllRegions, data: allRegionsData }: PreferencesApiType = useApi({
        url: 'user-preference/regions',
        method: 'GET'
    });

    const {
        makeRequest: getAllRoles,
        data: allRolesData,
        error: allRolesError,
        loading: allRolesLoading
    }: PreferencesApiType = useApi({
        url: 'user-preference/roles',
        method: 'GET'
    });

    const {
        makeRequest: getCountriesByRegion,
        data: countriesByRegionData,
        error: countriesByRegionError,
        loading: countriesByRegionLoading
    }: PreferencesApiType = useApi({
        url: `user-preference/${selectedUserPreferences.region?.id}/countries-by-region`,
        method: 'GET'
    });

    const {
        makeRequest: getSitesByCountry,
        data: sitesByCountryData,
        error: sitesByCountryError,
        loading: sitesByCountryLoading
    }: PreferencesApiType = useApi({
        url: `user-preference/${selectedUserPreferences.country?.id}/sites-by-country`,
        method: 'GET'
    });

    const {
        makeRequest: postUserPreferenceSelection,
        data: postUserPreferenceSelectionData,
        error: postUserPreferenceSelectionError,
        loading: postUserPreferenceSelectionLoading
    }: PostPreferencesSelectionApiType = useApi({
        url: 'user-preference-selection',
        method: 'POST'
    });

    const {
        makeRequest: getUserPreferences,
        data: getUserPreferencesData,
        error: getUserPreferencesError
    }: PreferencesSelectionApiType = useApi({
        url: 'user-preference-selection',
        method: 'GET'
    });

    useEffect(() => {
        getUserPreferences();
        getAllRegions();
        getAllRoles();
    }, []);

    useEffect(() => {
        if (getUserPreferencesData) {
            const selectedLanguageLocale = LANGUAGES_TO_LOCALE[getUserPreferencesData.language.path] || DEFAULT_LOCALE;
            setLocalizationContext(localizationContextBuilder.withLocale(selectedLanguageLocale).build());

            if (getUserPreferencesData.hasOwnProperty('refinementToggleOn')) {
                setRefinementToggleOn(getUserPreferencesData.refinementToggleOn);
                setSelectedRefinementToggleOn(getUserPreferencesData.refinementToggleOn);
            } 
            getUserPreferencesData.listViewStyle && setListViewStyle(getUserPreferencesData.listViewStyle);

            setSsoDetectedPreferences({});
        }

        if (getUserPreferencesData && getUserPreferencesData.region) {
            const { userId, refinementToggleOn, ...rest } = getUserPreferencesData;

            getCountriesByRegion({
                url: `user-preference/${getUserPreferencesData.region.id}/countries-by-region`
            })

            if (getUserPreferencesData.country?.id) {
                getSitesByCountry({
                    url: `user-preference/${getUserPreferencesData.country.id}/sites-by-country`
                });
            }

            setSelectedUserPreferences(rest);
            setPreferenceDataLoaded(true);

        } else if (getUserPreferencesError) {
            if (user?.country && !isPrivacyRestricted()) {
                const ssoDetectedCountry = COGNITO_COUNTRY_TO_COUNTRY_PREFERENCE[user.country] || preferencesDefault.country;
                const ssoDetectedRegion = COGNITO_COUNTRY_TO_REGION_PREFERENCE[user.country] || preferencesDefault.region;
                preferencesDefault.country = ssoDetectedCountry;
                preferencesDefault.region = ssoDetectedRegion;
                setSelectedUserPreferences(preferencesDefault);
                setPreferenceDataLoaded(true);
                setSsoDetectedPreferences({
                    country: ssoDetectedCountry, 
                    region: ssoDetectedRegion
                });
            }
            getCountriesByRegion({
                url: `user-preference/${preferencesDefault.region?.id}/countries-by-region`
            })

            getSitesByCountry({
                url: `user-preference/${preferencesDefault.country?.id}/sites-by-country`
            });
        }
    }, [getUserPreferencesData, getUserPreferencesError]);

    useEffect(() => {
        if (countriesByRegionData) {
            setCountries(countriesByRegionData)
        }

    }, [countriesByRegionData]);

    useEffect(() => {
        if (sitesByCountryData) {
            setSites(sitesByCountryData)
        }

    }, [sitesByCountryData]);

    useEffect(() => {
        if (postUserPreferenceSelectionData?.status) {
            getUserPreferences();
            setPreferencesOpen(false);
            saveUserPreferenceMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.user.preference.save.userId', user?.hashedUserId || ''));
        }
    }, [postUserPreferenceSelectionData])

    useEffect(() => {
        if (postUserPreferenceSelectionError) {
            saveUserPreferenceErrorMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.error.errorType', 'Save User Preference'))
            setHasErrored(true)
            setPreferencesOpen(false);
            publishNotification({
                type: "error",
                content: (
                    <>
                        {t('preferences_error', "Your preferences could not be saved. Please try again later")}
                    </>
                ),
                id: NOTIFICATIONS.PREFERENCES_ERROR
            })
        }
    }, [postUserPreferenceSelectionError]);


    const handleSelectionChange = (key: keyof PreferenceSelection, id: string) => {

        const preferences = { ...selectedUserPreferences };

        switch (key) {
            case 'region':
                preferences.region = allRegionsData.find(region => String(region.id) === id)!;
                delete preferences.country;
                setCountries([]);
                delete preferences.site;
                setSites([]);
                getCountriesByRegion({
                    url: `user-preference/${preferences.region?.id}/countries-by-region`
                })
                break;
            case 'country':
                preferences.country = countries?.find(country => String(country.id) === id)!;
                preferences.site = preferencesDefault.site!;
                setSites([]);
                getSitesByCountry({
                    url: `user-preference/${preferences.country?.id}/sites-by-country`
                });
                break;
            case 'site':
                preferences.site = sites?.find(site => String(site.id) === id)!;
                break;
            case 'language':
                preferences.language = languages.find(lang => String(lang.id) === id)!
                break;
            case 'role':
                preferences.role = allRolesData.find(role => String(role.id) === id)!
                break;

            default:
                break;
        }

        setSelectedUserPreferences(preferences);

    }

    const canPreferencesSaved = () => !postUserPreferenceSelectionLoading;
    const resetPreferencesSelection = () => {
        if (!isEqual(getUserPreferencesData, selectedUserPreferences)) {
            getUserPreferences();
        }
        setPreferencesOpen(false);
    }

    const savePreferences = () => {
        if (!canPreferencesSaved()) return;
        postUserPreferenceSelection({
            data: { 
                ...selectedUserPreferences,
                refinementToggleOn: selectedRefinementToggleOn,
                listViewStyle
            }
        })
    }

    const updateRefinementToggleState = (value: boolean) => {
        setSelectedRefinementToggleOn(value);
    }

    const updateListViewStyleState = (value: string) => {
        setListViewStyle(value);
        postUserPreferenceSelection({
            data: {
                ...getUserPreferencesData || preferencesDefault,
                refinementToggleOn: selectedRefinementToggleOn,
                listViewStyle: value
            }
        })
    }


    const value: PreferencesContextType = {
        selectedUserPreferences,
        ssoDetectedPreferences,
        userPreferences: getUserPreferencesData || preferencesDefault,
        regions: allRegionsData,
        roles: allRolesData,
        isSaving: postUserPreferenceSelectionLoading,
        preferenceDataLoaded,
        countries,
        sites,
        languages,
        canPreferencesSaved,
        hasErrored,
        refinementToggleOn,
        selectedRefinementToggleOn,
        listViewStyle,
        handleSelectionChange,
        resetPreferencesSelection,
        savePreferences,
        updateRefinementToggleState,
        updateListViewStyleState,
    }

    return <PreferencesContext.Provider value={value}>{children}</PreferencesContext.Provider>
}