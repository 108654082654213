import React, { useEffect, useState } from "react";
import { AppLayout, Button, SideNavigation, SideNavigationProps, Tabs } from "@cloudscape-design/components";
import { useTutorials } from "src/components/providers/TutorialsProvider";
import { getHelpContent } from "src/tutorials/help-content";
import { TABS } from "src/tutorials/enums";
import { useTranslate } from "src/i18n/useTranslate";
import { useLocation } from "react-router";
import { useWelcomeModal } from "src/components/providers/WelcomeModalProvider";

interface AppLayoutWrapperProps {
    content: React.JSX.Element;
    sideNavStructure?: SideNavigationProps.Item[];
    hrefMap?: Map<string, string>;
    onSubtopicSelection?: (subtopicDetails: CustomEvent<SideNavigationProps.FollowDetail>) => void;
    isDetailsPage?: boolean;
}


export const AppLayoutWrapper = ({ content, sideNavStructure, isDetailsPage, onSubtopicSelection, hrefMap }: AppLayoutWrapperProps) => {
    const [sideNavOpen, setSideNavOpen] = useState<boolean>(true);
    const [activeHref, setActiveHref] = React.useState(
        ""
    );

    const location = useLocation();

    const { activeTabId, setActiveTabId, toolsOpen, setToolsOpen, appLayoutRef, helpPanelTopic } = useTutorials();
    const t = useTranslate();
    const { setWelcomeGruOpen } = useWelcomeModal();

    useEffect(() => {
        if (isDetailsPage) {
            const { hash } = location;
            if (hash) {
                setActiveHref(hash);

                const sectionId = hash.split('/')[1] || '';
                if (hrefMap && hrefMap.has(sectionId)) {
                    setActiveHref(hrefMap.get(sectionId)!)
                }
            }
        }
    }, [location.hash])

    return (
        <AppLayout
            ref={appLayoutRef}
            headerSelector="#top-navigation"
            navigationHide={!sideNavStructure?.length}
            navigationOpen={sideNavOpen}
            navigation={
                <SideNavigation
                    activeHref={activeHref}
                    onFollow={event => {
                        if (!event.detail.external && event.detail.href) {
                            setActiveHref(event.detail.href);

                            if (onSubtopicSelection) {
                                onSubtopicSelection(event);
                            }
                        }
                    }}
                    items={sideNavStructure}
                />
            }
            onNavigationChange={({ detail }) => setSideNavOpen(detail.open)}
            maxContentWidth={Number.MAX_VALUE}
            toolsOpen={toolsOpen}
            onToolsChange={({ detail }) => setToolsOpen(detail.open)}
            tools={<Tabs
                activeTabId={activeTabId}
                onChange={({ detail }) => {
                    setActiveTabId(detail.activeTabId as TABS)
                }}
                tabs={[
                    {
                        id: 'help-panel', label: t('help-panel-label', 'Info'), content: getHelpContent(helpPanelTopic)
                    },
                    {
                        id: 'tutorials-panel',
                        label: t('tutorials-panel-label', 'Tutorials'),
                        content: (
                            <div className="flex-row justify-content-center" >
                                <Button onClick={() => {
                                    setWelcomeGruOpen(true);
                                    setToolsOpen(false);
                                }} >
                                    {t('launch_gru_tutorial', 'Launch GRU Tutorial')}
                                </Button>
                            </div>
                        ),
                    },
                ]}
            />}
            content={content}
        />
    )
}