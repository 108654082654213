import React, { useContext, useEffect } from "react";
import { useS3ProxyApi } from "src/hooks/useS3ProxyApi";
import { NavGraph, NavGraphItem } from "src/types/navGraph";
import { NavGraphApiType } from "src/types/useApi";

export const NAV_GRAPH_ROOT_NODE_ID = 357;

export interface NavGraphContextType {
    navGraph: NavGraph;
    isLoading: boolean;
    hasError: boolean;
    getBreadcrumbNamesFromPath: (path?: string) => string[];
}

export const NavGraphContext = React.createContext<NavGraphContextType>({
    navGraph: {},
    isLoading: false,
    hasError: false,
    getBreadcrumbNamesFromPath: () => []
})

export const useNavGraph = () => useContext(NavGraphContext)

interface NavGraphProviderProps {
    children: React.ReactNode;
}

export const NavGraphProvider = ({ children }: NavGraphProviderProps) => {

    const { makeRequest, data, error, loading }: NavGraphApiType = useS3ProxyApi({
        url: 'nav-graph',
        method: 'GET'
    });

    useEffect(() => {
        makeRequest();
    }, []);

    const getBreadcrumbNamesFromPath = (path?: string) => {
        const names: string[] = [];
        if (!data ||  !path || !path.startsWith(`${NAV_GRAPH_ROOT_NODE_ID}/`)) return names;

        const pathArray = path.split('/').filter(Boolean);
        //  remove first 357 path
        pathArray.shift();

        let currentBlock: NavGraphItem | undefined;

        pathArray.forEach((path, index) => {
            currentBlock = index === 0 ? data[path] : currentBlock?.children?.[path];
            if (currentBlock) {
                names.push(currentBlock.name);
            }
        });

        return names;

    }


    const value: NavGraphContextType = {
        navGraph: data,
        isLoading: loading,
        hasError: !!error,
        getBreadcrumbNamesFromPath,
    }

    return <NavGraphContext.Provider value={value}>{children}</NavGraphContext.Provider>
}