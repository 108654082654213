import React from 'react';
import '../styles/list-pages.scss';
import { Box, ButtonDropdown, Cards, Link, SpaceBetween, TextContent } from '@cloudscape-design/components';
import { useTranslate } from 'src/i18n/useTranslate';
import { useFavorites } from 'src/components/providers/FavoritesProvider';
import { useNavigate } from 'react-router';
import { getLocaleDate, openExternalUrl } from 'src/helpers';
import { useNavGraph } from 'src/components/providers/NavGraphProvider';
import { useRecentlyViewed } from './providers/RecentlyViewedProvider';

export interface ArtifactCard {
    id: number;
    breadcrumbPath?: string;
    title: string;
    locale?: string;
    description?: string;
    lastUpdatedDate?: string;
    language?: string;
    externalUrl?: string;
}

export interface ArtifactsSectionProps {
    artifacts: ArtifactCard[];
    name: string;
    viewAllPath: string;
}

export const ArtifactsSection = ({ artifacts, name, viewAllPath }: ArtifactsSectionProps) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const { addFavorite, deleteFavorite, isFavorite } = useFavorites();
    const { addRecentlyViewed } = useRecentlyViewed();
    const { getBreadcrumbNamesFromPath } = useNavGraph();


    const buildBreadcrumbText = (path: string) => {
        const names = getBreadcrumbNamesFromPath(path);
        return names.join(" > ");
    }

    const handleTitleOnFollow = (item: ArtifactCard) => {
        if (item.externalUrl) {
            openExternalUrl(item.externalUrl);
            addRecentlyViewed(item.id);
        } else {
            navigate(`/${item.id}`)
        }
    }

    if (!artifacts.length) return null;

    return (
        <SpaceBetween size='m' >

            <div className='flex-row justify-space-between align-items-center'>
                <Box variant="h2" data-testid="artifacts-section-header" >{name}</Box>

                <Link href='' onFollow={() => navigate(viewAllPath)} >{t("landing_page_recently_view_all", "View All")}</Link>

            </div>

            <Cards
                data-all-cards-container
                ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.title}`,
                    selectionGroupLabel: "Item selection"
                }}
                cardDefinition={{
                    header: item => {
                        return (
                            <div data-list-page-card data-testid="artifacts-section-card" >
                                <Link href="#" onFollow={() => handleTitleOnFollow(item)} fontSize="heading-m">
                                    {item.title}
                                </Link>
                                <ButtonDropdown
                                    onItemClick={({ detail }) => {
                                        const id = detail.id;

                                        switch (id) {
                                            case "favorite":
                                                deleteFavorite(item.id);
                                                break;
                                            case "not-favorite":
                                                addFavorite(item.id);
                                                break;
                                            case "copy-link":
                                                navigator.clipboard.writeText(`${window.location.origin}/${item.id}`);
                                                break;

                                            default:
                                                break;
                                        }
                                    }}
                                    data-list-page-card-dropdown-container
                                    data-testid={"article-button-dropdown"}
                                    items={[
                                        {
                                            id: "copy-link",
                                            text: t("landing_page_card_options_copy", "Copy link"),
                                            iconName: "copy"
                                        },
                                        {
                                            id: isFavorite(item.id) ? "favorite" : "not-favorite",
                                            text: isFavorite(item.id) ? t('remove-from-favorites', "Remove from Favorites") : t('add-to-favorites', "Add to Favorites"),
                                            iconName: "heart"
                                        },
                                    ]}
                                    ariaLabel="Control instance"
                                    variant="inline-icon" />
                            </div>
                        );
                    },
                    sections: [
                        {
                            id: "description",
                            header: "",
                            content: item => item.description
                        },
                        {
                            id: 'path',
                            header: '',
                            content: item => (<TextContent><p data-list-path><small>{buildBreadcrumbText(item.breadcrumbPath || "")}</small></p></TextContent>),
                        },
                        {
                            id: 'locale',
                            header: '',
                            content: item => (
                                <TextContent>
                                    <p data-list-locale><small>{`${item.language}${item.locale ? ', ' + item.locale : ""}`}</small></p>
                                </TextContent>
                            )
                        },
                        {
                            id: 'last-modified',
                            header: '',
                            content: item => (<TextContent><p data-list-last-modified><small>{t("details-page-last-modified", "Last modified on")} <small data-last-modified-date>{getLocaleDate(item.lastUpdatedDate)}</small></small></p></TextContent>),
                        },
                    ]
                }}
                cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 500, cards: 4 }
                ]}
                items={artifacts?.slice(0, 4)}
            />
        </SpaceBetween>
    );
};