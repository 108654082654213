import {
    LocalizationContextBuilder,
    MessageBundle,
    LocalizationContext,
} from '@amzn/arb-tools';
import translations from 'translations';
import arbManifest from 'translations/arbManifest';
import { DEFAULT_LOCALE } from './constants';
import { PAGE } from './pages/enums';

const LOCALIZATION_CONTEXTS = new Map<string, LocalizationContext>();

export enum Stage {
    TEST = 'test',
    BETA = 'beta',
    GAMMA = 'gamma',
    PROD = 'prod',
}

export function getLocalizationContext(locale: string) {
    let lc = LOCALIZATION_CONTEXTS.get(locale);

    if (!lc) {
        lc = new LocalizationContextBuilder()
            .withLocale(locale)
            .withDefaultLocale(DEFAULT_LOCALE)
            .build();

        LOCALIZATION_CONTEXTS.set(locale, lc);
    }

    return lc;
}

export function t(
    bundle: MessageBundle,
    stringId: string,
    args?: Record<string, unknown>
) {
    if (!bundle || !stringId) {
        return undefined;
    }

    if (!args) {
        return bundle.getMessage(stringId);
    }

    return bundle.formatMessage(stringId, args);
}

export function resolveBundleUrlWithFallbacks(
    resourcePackPath: string | undefined,
    locale: string,
    bundle: string
) {
    if (resourcePackPath && translations[resourcePackPath]) {
        return translations[resourcePackPath];
    }

    if (!resourcePackPath && arbManifest.endpointMap?.[locale]?.[bundle]) {
        return translations[arbManifest.endpointMap[locale][bundle]];
    }

    if (arbManifest.endpointMap?.[DEFAULT_LOCALE]?.[bundle]) {
        return translations[arbManifest.endpointMap[DEFAULT_LOCALE][bundle]];
    }
}

export const getStage = () => {
    const stage = 'gamma';
    return stage as Stage;
};

export const isStageProdOrGamma = () => {
    const stage = getStage();
    return stage === Stage.PROD || stage === Stage.GAMMA;
}

export const getPrivacyRestrictedCountries = (override?: string): string[] => {
    let privacyRestrictedCountries: string[] = [];
    try {
        const privacyRestrictedCountriesVariable = override ?? '["DEU"]';
        privacyRestrictedCountries = JSON.parse(privacyRestrictedCountriesVariable.replace(/\\"/g, '"'));
    } catch (error) {
        console.error("Error while parsing PrivacyRestrictedCountries deployment variable: " + error);
    }

    return privacyRestrictedCountries;
}

export const addMonthsToDate = (date: Date, numberOfMonths: number = 0) => {
    date.setMonth(date.getMonth() + numberOfMonths);
    return date;
}

export const getPageName = (pathname: string): PAGE => {
    switch (pathname) {
        case "/":
            return PAGE.HOME;
        case "/search":
            return PAGE.SEARCH;
        case "/not-found":
            return PAGE.NOT_FOUND;
        case "/favorites":
            return PAGE.FAVORITES;
        case "/recent":
            return PAGE.RECENT;

        default:
            return PAGE.CONTENT;
    }
}

export const getLocaleDate = (date: string | Date = new Date()) => {
    return new Date(date).toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric"
    });
}

export const openExternalUrl = (url: string) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "https://" + url;
    }

    window.open(url, "_blank");
}