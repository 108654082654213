import { Box, Button, Modal, SpaceBetween, TextContent } from "@cloudscape-design/components";
import '../styles/preferences-pane.scss';
import Content from "./Content";

interface ExploreModalProps{
    exploreModalOpen: boolean;
    setExploreModalOpen: (isOpen: boolean) => void;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

export default function ExploreModal({
    exploreModalOpen,
    setExploreModalOpen,
    setHasOpenedInNewTab,
}: ExploreModalProps) {
    return (
        <Modal
            onDismiss={() => setExploreModalOpen(false)}
            visible={exploreModalOpen}
            size="max"
        >
            <Content modalVersion setExploreModalOpen={setExploreModalOpen} setHasOpenedInNewTab={setHasOpenedInNewTab} />
        </Modal>
    );

}