import { TextContent } from "@cloudscape-design/components";
import { useTranslate } from "../i18n/useTranslate";
import { useWelcomeModal } from "./providers/WelcomeModalProvider";

const Footer = () => {
  const t = useTranslate();
  const { setWelcomeGruOpen } = useWelcomeModal();
  const currentYear = { "currentYear": new Date().getFullYear() };

  return (
    <div style={styles.container}>
      <TextContent>
        {/* @ts-ignore */}
        <div style={styles.footerTextContainer}>
          <div>
            <strong><a data-testid="footer-welcome" style={styles.footerLink} onClick={() => {
              setWelcomeGruOpen(true);
              window.scrollTo({ top: 0 });
            }}>{t('footer_gru_tutorial', 'GRU Tutorial')}</a></strong>
            <div style={styles.vr}></div>
            <strong><a style={styles.footerLink} href="https://w.amazon.com/bin/view/Amazon_Robotics/BOS/LExT" target="_blank" rel="noopener">{t('footer_about_team', 'About LExT')}</a></strong>
            <div style={styles.vr}></div>
            <strong><a style={styles.footerLink} href="https://w.amazon.com/bin/view/Amazon_Robotics/BOS/LExT/GRU/" target="_blank" rel="noopener">{t('gru_wiki', 'GRU Wiki')}</a></strong>
            <div style={styles.vr}></div>
            <strong><a style={styles.footerLink} href="https://river.amazon.com/?org=ARLExT&buildingId=BOS12&q0=23682267-25a3-4faa-b4a9-5ceb65d66c60&q1=81cc7c0c-8f26-4679-a67c-680932334103" target="_blank" rel="noopener">{t('footer_gru_support', 'GRU Support')}</a></strong>
            <div style={styles.vr}></div>
            <strong><a style={styles.footerLink} href="https://river.amazon.com/?org=ARLExT" target="_blank" rel="noopener">{t('footer_content_request', 'Content Request')}</a></strong>
            <div style={styles.vr}></div>
            <strong><a style={styles.footerLink} href="https://archer-na.corp.amazon.com/" target="_blank" rel="noopener">ARCHER</a></strong>
          </div>
          <div style={styles.text}>
            <span>&copy; </span>
            {
              t('footer_copyright', `${currentYear.currentYear} Amazon GRU. All rights reserved.`, currentYear)
            }
          </div>
        </div>
      </TextContent>
    </div>
  );
}

const styles = {
  container: {
    width: "100%",
    padding: "24px 0px",
    backgroundColor: "#0F1B2A",
  },
  text: {
    marginTop: '16px',
    color: "white",
  },
  footerTextContainer: {
    textAlign: 'center'
  },
  footerLink: {
    display: 'inline-block',
    color: 'white',
    textDecoration: 'none'
  },
  vr: {
    display: 'inline-block',
    height: '12px',
    width: '1px',
    borderRight: "1px solid #414D5C",
    margin: "0 10px"
  },
};

export default Footer;
