import React, { useContext, useState } from "react";

export interface WelcomeModalContextType {
    welcomeGruOpen: boolean;
    setWelcomeGruOpen: (open: boolean) => void;
    preferencesModalOpen: boolean;
    setPreferencesModalOpen: (open: boolean) => void;
}

export const WelcomeModalContext = React.createContext<WelcomeModalContextType>({
   welcomeGruOpen: false,
   setWelcomeGruOpen: () => {},
   preferencesModalOpen: false,
   setPreferencesModalOpen: () => {},
})

export const useWelcomeModal = () => useContext(WelcomeModalContext)

interface ModalProviderProps {
    children: React.ReactNode;
}

export const WelcomeModalProvider = ({ children }: ModalProviderProps) => {
    const [welcomeGruOpen, setWelcomeGruOpen ] = useState(false);
    const [preferencesModalOpen, setPreferencesModalOpen] = useState(false);


    const value: WelcomeModalContextType = {
        welcomeGruOpen,
        setWelcomeGruOpen,
        preferencesModalOpen,
        setPreferencesModalOpen
    }

    return <WelcomeModalContext.Provider value={value}>{children}</WelcomeModalContext.Provider>
}