import React, { useEffect } from 'react';
import { Button, ButtonProps } from "@cloudscape-design/components";

interface HotKeyButtonProps {
    onClick: () => void;
    children: React.ReactNode;
    variant?: ButtonProps.Variant;
    'data-testid'?: string;
    disabled?: boolean;
    hotKeyIdentifier: string;
}

const HotKeyButton: React.FC<HotKeyButtonProps> = ({ 
    onClick, 
    children, 
    variant, 
    'data-testid': dataTestId, 
    disabled, 
    hotKeyIdentifier 
}) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!disabled && event.shiftKey && event.key === hotKeyIdentifier) {
                onClick();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClick]);

    return (
        <Button 
            onClick={onClick} 
            variant={variant} 
            data-testid={dataTestId} 
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

export default HotKeyButton;