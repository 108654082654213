import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import UserPreferencesImage from "src/images/tutorial-user-preferences.jpg";

export const UserPreferencesScreen = () => {
  const t = useTranslate();

  return (
    <SpaceBetween data-testid="welcome-gru-user-preferences-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={UserPreferencesImage} height={300} />
      </div>
      <Box variant="h3">
        {t(
          "welcome_gru_user_preferences_header",
          "Welcome to GRU! Be sure to check out the welcome video tutorial linked below."
        )}
      </Box>
      <Box variant="p">
        {t(
          "welcome_gru_user_preferences_description",
          "GRU uses <b>User Preferences</b> to provide a personalized and relevant experience when searching for and browsing for content. Ensure you identify your region, country, site, language and role. GRU content is filtered based on your selected location, language, and role. When you choose a language other than English, you have the option to include English content as well. Also, the GRU user interface text like buttons, prompts, descriptions, and more are displayed in your selected language. Facilitators and trainers can choose the Trainer role to gain full access to all learner-facing content and exclusive trainer resources, including detailed facilitator guides, for effortless delivery of instructor-led trainings.",
          { b: (chunk: string) => <strong key={chunk}>{chunk}</strong> }
        )}
      </Box>
    </SpaceBetween>
  );
};
