import React from 'react';
import { createRoot } from 'react-dom/client';
import Spinner from "@cloudscape-design/components/spinner";
import { Amplify } from "aws-amplify";

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import amplifyConfig from "./amplifyConfig"
import './logger';

import "@cloudscape-design/global-styles/index.css"
import './index.scss';
import { BrowserRouter } from 'react-router-dom';

Amplify.configure(amplifyConfig)

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <AppInitWrapper>
            <React.Suspense fallback={<Spinner size="large" />}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </React.Suspense>
        </AppInitWrapper>
    </React.StrictMode>
);
