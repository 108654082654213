import TopNavigation from "@cloudscape-design/components/top-navigation";
import logo from "../images/GRU-Icon-2.svg";
import { useAuth } from "./providers/AuthProvider";
import { Button, Hotspot, TextContent } from "@cloudscape-design/components";
import { useLocation, useNavigate } from "react-router";
import { usePreferences } from "./providers/PreferencesProvider";
import { LANGUAGES_TO_COUNTRY } from "src/constants";
import { useTranslate } from "src/i18n/useTranslate";
import { HOT_SPOTS } from "src/tutorials/enums";
import initialMetricsPublisher from "src/metrics";
import * as KatalMetrics from '@amzn/katal-metrics';
import { PAGE } from "src/pages/enums";
import '../styles/navigation.scss';
import { useState } from "react";
import { SearchAutoSuggest } from "./SearchAutoSuggest";

interface NavigationProps {
    setPreferencesOpen: (isOpen: boolean) => void;
    setExploreModalOpen: (isOpen: boolean) => void;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

const Navigation = ({setPreferencesOpen, setExploreModalOpen, setHasOpenedInNewTab,}: NavigationProps) => {
  const t = useTranslate();
  
  const { user } = useAuth()
  const { userPreferences } = usePreferences();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  
  const isOnHomepage = location.pathname === "/";
  const countryAbbreviation = LANGUAGES_TO_COUNTRY[userPreferences.language.path] || "us";

  const clickExploreButtonMetricsPublisher = initialMetricsPublisher
    .newChildActionPublisherForMethod('ClickExploreButton');

  const clickExploreButton = () => {
    setExploreModalOpen(true)
    
    clickExploreButtonMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.explore.click', user?.hashedUserId || ''))
  }

  return (
    <div id="top-navigation">

      <TopNavigation
        identity={{
          href: "/",
          title: "GRU",
          logo: {
            src: logo,
            alt: "Global Robotics University"
          }
        }}
        utilities={[
          {
            type: "button",
            text: `${userPreferences?.language.name} - ${userPreferences?.role.name}`,
            iconUrl: `https://d1uznvntk80v7s.cloudfront.net/flags/${countryAbbreviation}.svg`,
            onClick: () => setPreferencesOpen(true) 
          },
          {
            type: "button",
            text: t('favorite-breadcrumb', "Favorites"),
            iconName: "heart",
            onClick: () => navigate(`/${PAGE.FAVORITES}`) 
          },
          {
            type: "button",
            text: user?.name,
            iconName: "user-profile",
          },
        ]}
        search={
          !isOnHomepage ? (
            <div>
              <TextContent>
                <Button data-explore-button onClick={() => {clickExploreButton()}}>
                  <h4 style={{color: 'white'}}>{t('header_explore_label', 'Explore')}</h4>
                </Button>
              </TextContent>
              <div data-navigation-search-input >
                <SearchAutoSuggest
                  value={searchInput}
                  setValue={setSearchInput}
                  setHasOpenedInNewTab={setHasOpenedInNewTab}
                  placeholder={t('search_placeholder_text_type_a_keyword', 'Type a keyword to search GRU')}
                />
              </div>
            </div>
          ) : (<></>)
        }
      />
      <div className="navigation-hotspot-wrapper">
        <div className="navigation-hotspot-container">

        <Hotspot hotspotId={HOT_SPOTS.TOP_NAV_BAR_WELCOME} direction="bottom" side="right"  />
        <Hotspot hotspotId={HOT_SPOTS.TOP_NAV_BAR_USER_PREFERENCES} direction="bottom" side="right"  />
        <Hotspot hotspotId={HOT_SPOTS.TOP_NAV_BAR_UPDATED_PREFERENCES} direction="bottom" side="right"  />
        </div>
      </div>
    </div>
  );
}

export default Navigation;