

import '../styles/list-pages.scss';
import { useEffect, useState } from 'react';
import { useTranslate } from 'src/i18n/useTranslate';
import { usePreferences } from 'src/components/providers/PreferencesProvider';
import ListPage from './list-page';
import { SearchResultsApiType } from 'src/types/useApi';
import { useApi } from 'src/hooks/useApi';
import { useSearchParams } from 'react-router-dom';
import { SearchQueryResult, SearchResult, SearchResultsForCards } from 'src/types/searchResult';
import { ENGLISH_PATH, EXTENDED_SEARCH_PATHS, SEARCH_LANGUAGE_MAP, USA_PATH, filterSearchResults, formatSearchItemsForCards } from 'src/utils/searchUtils';
import { useNavGraph } from 'src/components/providers/NavGraphProvider';
import initialMetricsPublisher from "src/metrics";
import * as KatalMetrics from '@amzn/katal-metrics';
import { AppLayout, Box, Button, Checkbox, ExpandableSection, SpaceBetween, Tabs, TextContent, TokenGroup } from '@cloudscape-design/components';
import { NavGraph, NavGraphItem } from 'src/types/navGraph';
import { ChosenSearchClassification } from 'src/types/search';
import { useTutorials } from 'src/components/providers/TutorialsProvider';
import { getHelpContent } from 'src/tutorials/help-content';
import { useWelcomeModal } from 'src/components/providers/WelcomeModalProvider';
import { TABS } from 'src/tutorials/enums';
import { useAuth } from 'src/components/providers/AuthProvider';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { NOTIFICATIONS, useNotifications } from 'src/components/providers/NotificationsProvider';

let searchApiTimeout: ReturnType<typeof setTimeout>;
const MAX_QUERY_SIZE = 48;
const MAX_PAGE_SIZE = 24;

const SearchPage = () => {
    const t = useTranslate();
    const { publishNotification, notifications } = useNotifications();
    const [searchParams] = useSearchParams();
    const { userPreferences, refinementToggleOn, updateRefinementToggleState, selectedUserPreferences, preferenceDataLoaded, ssoDetectedPreferences } = usePreferences();
    const { navGraph } = useNavGraph();
    const { user } = useAuth();

    const [chosenFilters, setChosenFilters] = useState<ChosenSearchClassification[]>([]);
    const [languageFacetFilter, setLanguageFacetFilter] = useState('');

    const [sideNavCategories, setSideNavCategories] = useState<any | undefined>();

    const { activeTabId, setActiveTabId, toolsOpen, setToolsOpen, helpPanelTopic, appLayoutRef } = useTutorials();
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [searchTakesLong, setSearchTakesLong] = useState(false);

    const { setWelcomeGruOpen } = useWelcomeModal();

    const [rawResults, setRawResults] = useState<SearchResult[]>([]);
    const [formattedResults, setFormattedResults] = useState<SearchResultsForCards[]>([]);
    const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = useState(MAX_PAGE_SIZE);

    const searchInput = searchParams.get('q');

    const { makeRequest: getSearchResults, data: searchResults, loading: searchResultsLoading, error: searchResultsError }: SearchResultsApiType = useApi({
        url: `learning-artifacts/search/topic`,
        method: 'GET'
    });

    useEffect(() => {
        if(!searchResultsLoading){
            clearTimeout(searchApiTimeout);
        }

    }, [searchResultsLoading]);

    useEffect(() => {
        if (searchResultsError && !hasErrored) {
            publishNotification({
                type: "error",
                content: (
                    <>
                        {t('gru_heavy_load_error', "Looks like GRU Search is under heavy load right now. Try loading again.")}
                    </>
                ),
                id: NOTIFICATIONS.SEARCH_ERROR
            })
            searchQueryMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.error.errorType', searchInput || ''))
            setHasErrored(true);
        }
    }, [searchResultsError, hasErrored])


    const searchQueryMetricsPublisher = initialMetricsPublisher
        .newChildActionPublisherForMethod('SearchQuery');

    const classifications = "[358,434,442]";

    const toggleCheckAndChildrenChecks = (parentNode: NavGraphItem, checked: boolean) => {
        if (checked && !shouldBeIndeterminate(parentNode?.children)) {
            const newChecks: any[] = [];
            if (parentNode?.children) {
                const children = Object.keys(parentNode.children).map(childId => parentNode?.children?.[childId]);
                children.forEach(child => {
                    if (!(chosenFilters.findIndex(fil => fil.label === child?.name) !== -1)) {
                        newChecks.push({
                            label: child?.name || "", dismissLabel: `Remove ${child?.name}`, path: child?.path || ""
                        })
                    }
                })
                if (newChecks.length) {
                    setChosenFilters([...chosenFilters, ...newChecks])
                }
            }
        } else {
            // uncheck parent
            const parentIndex = chosenFilters.findIndex(fil => fil.label == parentNode.name);
            let newFilters = structuredClone([
                ...chosenFilters.slice(0, parentIndex),
                ...chosenFilters.slice(parentIndex + 1)
            ]);

            // uncheck children 
            const childNodeNames = Object.values(parentNode.children || {}).map(ch => ch.name);
            const uncheckedChildrenFilters = newFilters.filter((fil: any) => {
                if (!childNodeNames.includes(fil.label)) {
                    return fil;
                }
            })

            setChosenFilters(uncheckedChildrenFilters);
        }
    }

    const toggleCheck = (checkName: string, path: string, checked: boolean) => {
        if (checked) {
            setChosenFilters([...chosenFilters, { label: checkName, dismissLabel: `Remove ${checkName}`, path }])
        } else {
            const itemIndex = chosenFilters.findIndex(fil => fil.label == checkName);
            setChosenFilters([
                ...chosenFilters.slice(0, itemIndex),
                ...chosenFilters.slice(itemIndex + 1)
            ]);
        }
    }

    const shouldBeChecked = (filterName: string): boolean => {
        return !!(filterName && chosenFilters.filter(fil => fil.label === filterName).length > 0)
    }

    const shouldParentBeChecked = (filterNames?: NavGraph): boolean => {
        if (filterNames) {
            const childNames = Object.keys(filterNames).map(childId => filterNames[childId].name);
            let allChildLabelFound = true;
            childNames.forEach(cName => {
                if (chosenFilters.findIndex(x => x.label === cName) === -1) {
                    allChildLabelFound = false;
                }
            })
            return !!chosenFilters.length && allChildLabelFound;
        }

        return false;
    }

    const shouldBeIndeterminate = (filterNames?: NavGraph): boolean => {
        if (filterNames) {
            const childNames = Object.keys(filterNames).map(childId => filterNames[childId].name);
            let childLabelFound = false;
            chosenFilters.forEach(chosen => {
                if (childNames.includes(chosen.label)) {
                    childLabelFound = true;
                }
            })
            return childLabelFound;
        }

        return false;
    }

    const buildSideNavCategoryFilters = () => {
        const topLevelNavGraphKeys = Object.keys(navGraph);

        const checkboxes = topLevelNavGraphKeys.map((key) => {
            return (
                <div key={key}>
                    <ExpandableSection
                        data-search-filter-expandable-section
                        defaultExpanded
                        variant="container"
                        headerInfo={
                            <Checkbox
                                data-search-category-level="0"
                                onChange={({ detail }) => {
                                    toggleCheckAndChildrenChecks(navGraph[key], detail.checked)
                                }}

                                checked={shouldParentBeChecked(navGraph[key].children)}
                                indeterminate={!shouldParentBeChecked(navGraph[key].children) && shouldBeIndeterminate(navGraph[key].children)}
                                key={key}
                            >
                                {navGraph[key].name}
                            </Checkbox>
                        }
                        headerText=" "
                    >
                        {Object.keys(navGraph[key].children || {}).length && (
                            (Object.keys(navGraph[key].children || {}).map(childKey => {
                                return (
                                    <Checkbox
                                        data-search-category-level="1"
                                        onChange={({ detail }) => {
                                            const childName = navGraph[key].children?.[childKey].name;
                                            const childPath = navGraph[key].children?.[childKey].path || "";
                                            if (childName) {
                                                toggleCheck(childName, childPath, detail.checked);
                                            }
                                        }
                                        }
                                        checked={shouldBeChecked(navGraph[key].children?.[childKey].name || "")}
                                        key={childKey}

                                    >
                                        {navGraph[key].children?.[childKey].name}
                                    </Checkbox>
                                )
                            })
                            ))}
                    </ExpandableSection>
                </div>
            )
        })
        return (
            <ExpandableSection headerText={t("search_page_sidebar_category", "Category")} defaultExpanded>
                {checkboxes}
            </ExpandableSection>
        )
    }

    const buildLanguageFacetFilter = () => {
        if (!userPreferences.language.name) return "";

        const userLanguage = (SEARCH_LANGUAGE_MAP as any)?.[userPreferences.language.name];
        const maybeIncludeEnglish = refinementToggleOn && userLanguage !== 'english' ? ";english" : "";
        return `{"language":"${userLanguage}${maybeIncludeEnglish}"}`;
    }

    useEffect(() => {
        userPreferences.language.name && setLanguageFacetFilter(buildLanguageFacetFilter());
    }, [userPreferences.language.name, refinementToggleOn])

    useEffect(() => {
        if (navGraph) {
            setSideNavCategories(buildSideNavCategoryFilters());
        }
    }, [navGraph, chosenFilters])

    useEffect(() => {
        searchInput && languageFacetFilter && makeNewSearchRequest();
    }, [searchInput, languageFacetFilter])

    const makeConsecutiveSearchRequest = async (minNumberOfResults: number, isNewRequest: boolean = false) => {
        setHasErrored(false);
        setSearchTakesLong(false);
        searchApiTimeout = setTimeout(() => {
            setSearchTakesLong(true);
            searchQueryMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.search.responseTimeThreshold', searchInput || ''));
        }, 3000);
        const response = await getSearchResults(
            {
                params: {
                    query: searchInput,
                    limit: MAX_QUERY_SIZE,
                    facetFilters: languageFacetFilter,
                    classifications,
                    start: isNewRequest ? 0 : (searchResults?.start || 0)
                }
            }
        );

        const firstResponseData = response?.data as SearchQueryResult;
        const firstRawResults = firstResponseData?.results || [];
        const firstFilteredResults = filterSearchResults(firstResponseData?.results, userPreferences, chosenFilters, refinementToggleOn);

        let secondFilteredResults: SearchResult[] = [];
        let secondRawResults: SearchResult[] = [];
        /* istanbul ignore next */
        if (firstResponseData) {

            if (firstResponseData?.hasMore && firstFilteredResults?.length < minNumberOfResults) {
                const secondResponse = await getSearchResults(
                    {
                        params: {
                            query: searchInput,
                            limit: MAX_QUERY_SIZE,
                            facetFilters: languageFacetFilter,
                            classifications,
                            start: firstResponseData?.start
                        }
                    }
                );
                const secondResponseData = secondResponse?.data as SearchQueryResult;
                secondRawResults = secondResponseData?.results || [];
                secondFilteredResults = filterSearchResults(secondResponseData?.results, userPreferences, chosenFilters, refinementToggleOn);
            }
        }

        setRawResults((prevRawResults) => [...prevRawResults, ...firstRawResults, ...secondRawResults]);

        return [...firstFilteredResults, ...secondFilteredResults];
    }

    /* istanbul ignore next */
    const makeMemorySearchRequest = async (updateNumberOfItemsToDisplay = false) => {
        const results = await makeConsecutiveSearchRequest(6);

        const oldResults = rawResults && refinementToggleOn ? filterSearchResults(rawResults, userPreferences, chosenFilters, refinementToggleOn) : (rawResults || []);
        const newResults = [...oldResults, ...results];

        if (updateNumberOfItemsToDisplay && newResults.length > numberOfItemsToDisplay) {
            setNumberOfItemsToDisplay(numberOfItemsToDisplay + MAX_PAGE_SIZE);
        }
    }

    const makeNewSearchRequest = async () => {
        if (!searchInput || !languageFacetFilter) return;

        searchQueryMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.search.query', searchInput || ''));
        searchQueryMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.search.query.userId', user?.hashedUserId || ''));

        setRawResults([]);
        setFormattedResults([]);
        setNumberOfItemsToDisplay(MAX_PAGE_SIZE);
        
        makeConsecutiveSearchRequest(30, true);
    }

    /* istanbul ignore next */
    const handleLoadMore = () => {
        if (formattedResults.length > numberOfItemsToDisplay) {
            setNumberOfItemsToDisplay(numberOfItemsToDisplay + MAX_PAGE_SIZE);
        } else {
            makeMemorySearchRequest(true);
        }
    }

    const filterRawResultsAndRender = (allowRetry: boolean = false) => {
        // don't render if there aren't any results yet
        if (searchResults?.results.length === 0 || rawResults.length === 0) return;

        const filteredSearchResults = filterSearchResults(rawResults, userPreferences, chosenFilters, refinementToggleOn);

        if (allowRetry && filteredSearchResults.length === 0 && searchResults.hasMore) {
            makeConsecutiveSearchRequest(MAX_QUERY_SIZE + 1);
            return; // don't render until next query is complete
        }

        setFormattedResults(formatSearchItemsForCards(filteredSearchResults, navGraph));
    }

    useEffect(() => {
        filterRawResultsAndRender();
    }, [rawResults])

    useEffect(() => {
        // if language facet has changed, then let other useEffect make new query
        const newLanguageFacetFilter = buildLanguageFacetFilter();
        if (newLanguageFacetFilter !== languageFacetFilter) return;

        filterRawResultsAndRender(true);
    }, [userPreferences, chosenFilters]);

    const titleLocation = userPreferences.country ? userPreferences.country.name : userPreferences.region?.name;

    const displayedItems = formattedResults.slice(0, numberOfItemsToDisplay);

    const pageTitleFiltersOn = `${t('list_page_showing', "Showing")} ${displayedItems.length || ""} ${userPreferences.language.name} ${t('list_page_results_for', "results for")} "${searchInput}" ${t('list_page_available_in', "available in")} ${titleLocation || ""} ${t('list_page_for', "for")} ${userPreferences.role.name}`;
    const pageTitleFiltersOff = `${t('list_page_showing', "Showing")} ${displayedItems.length || ""} ${t('list_page_results_for', "results for")} "${searchInput}"`;

    const dynamicPageTitle = refinementToggleOn ?
        pageTitleFiltersOn :
        pageTitleFiltersOff;

    const nothingToQuery = !searchResults?.hasMore && formattedResults.length === displayedItems.length;
    const retriedQueriesWithoutResults = !!searchResults?.hasMore && displayedItems.length === 0;

    return (
        <AppLayout
            data-search-app-layout
            headerSelector="#search-filters-navigation"
            navigationHide={false}
            navigationOpen={true}
            navigation={
                <SpaceBetween size='s' data-search-side-navigation>
                    <div data-search-filters-header>
                        <TextContent>
                            <h3>{t('search_page_sidebar_filter', 'Filters')}</h3>
                        </TextContent>
                        <Button href="#" variant="link" onClick={() => {
                            updateRefinementToggleState(false);
                            setChosenFilters([]);
                        }}>
                            {t('search_page_sidebar_clear_all', 'Clear All')}
                        </Button>
                    </div>
                    {chosenFilters.length > 0 && (
                        <TokenGroup
                            data-search-token-group
                            onDismiss={({ detail: { itemIndex } }) => {
                                setChosenFilters([
                                    ...chosenFilters.slice(0, itemIndex),
                                    ...chosenFilters.slice(itemIndex + 1)
                                ]);
                            }}
                            items={chosenFilters}
                        />
                    )}
                    <div>
                        {sideNavCategories}
                    </div>
                </SpaceBetween>
            }
            maxContentWidth={Number.MAX_VALUE}
            toolsOpen={toolsOpen}
            onToolsChange={({ detail }) => setToolsOpen(detail.open)}
            tools={<Tabs
                activeTabId={activeTabId}
                onChange={({ detail }) => {
                    setActiveTabId(detail.activeTabId as TABS)
                }}
                tabs={[
                    {
                        id: 'help-panel', label: t('help-panel-label', 'Info'), content: getHelpContent(helpPanelTopic)
                    },
                    {
                        id: 'tutorials-panel',
                        label: t('tutorials-panel-label', 'Tutorials'),
                        content: (
                            <div className="flex-row justify-content-center" >
                                <Button onClick={() => {
                                    setWelcomeGruOpen(true);
                                    setToolsOpen(false);
                                }} >
                                    {t('launch_gru_tutorial', 'Launch GRU Tutorial')}
                                </Button>
                            </div>
                        ),
                    },
                ]}
            />}
            content={
                <ListPage
                    key={formattedResults?.length}
                    pageTitle={dynamicPageTitle}
                    items={displayedItems}
                    searchResultsLoading={searchResultsLoading}
                    appendedContent={<div style={{ marginBottom: '10px' }}>
                        {searchResultsLoading && !!displayedItems.length && (
                            <div className="flex-row justify-content-center align-items-center">
                                <LoadingSpinner spinnerSize="normal" />
                            </div>
                        )}
                        {!searchResultsLoading && (
                            <div className="flex-column justify-content-center align-items-center">
                                {nothingToQuery || retriedQueriesWithoutResults ? (
                                    <Box textAlign="center" variant="h4">
                                        {t("search_page_expand_query_message", "It looks like your search terms and filters aren't returning many results. Adjust your query or filters for better results.")}
                                    </Box>
                                ) : (
                                    <Button onClick={handleLoadMore}>{t("search_page_load_more", "Load more")}</Button>
                                )}
                            </div>
                        )}
                    </div>}
                    longLoad={searchTakesLong}
                />
            }
        />

    );
};

export default SearchPage;