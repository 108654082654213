import React, { useContext, useState } from "react";

import { FilterItem } from "../HierarchyFilters";
import { SharedLinkInput } from "src/types/sharedLink";
import { NavGraph } from "src/types/navGraph";

export interface ArtifactDetailsContextType {
    setBreadcrumbsFromDynamicLayout?: (crumbs: FilterItem[]) => void;
    setArtifactDetails?: (crumbs: FilterItem[], documentInfo: SharedLinkInput) => void;
    breadcrumbs?: string[];
    name?: string;
    dateUpdated?: string;
}

export const ArtifactDetailsContext = React.createContext<ArtifactDetailsContextType>({})

export const useArtifactDetails = () => useContext(ArtifactDetailsContext)

interface ArtifactDetailsProviderProps {
    customCrumbs?: Array<string>;
    artifactName?: string;
    artifactModificationDate?: string;
    children: React.ReactNode;
}

export const ArtifactDetailsProvider = ({ children, customCrumbs = [], artifactName = "", artifactModificationDate = "" }: ArtifactDetailsProviderProps) => {
    const [breadcrumbs, setBreadcrumbs] = useState<Array<string>>(customCrumbs as Array<string>);
    const [articleName, setArticleName] = useState<string>(artifactName);
    const [articleModificationDate, setArticleModificationDate] = useState<string>(artifactModificationDate);

    const emptyArticleDetails = () => {
        setBreadcrumbs([""]);
        setArticleName("");
        setArticleModificationDate("");
    }

    const setArtifactDetails = (crumbs: FilterItem[], documentInfo: SharedLinkInput) => {
        emptyArticleDetails()
        setBreadcrumbsFn(crumbs);

        if(documentInfo.title) {
            setArticleName(documentInfo.title)
        }

        if(documentInfo.dateUpdated) {
            setArticleModificationDate(documentInfo.dateUpdated)
        }
    }

    const setBreadcrumbsFromDynamicLayout = (crumbs: FilterItem[]) => {
        emptyArticleDetails()
        setBreadcrumbsFn(crumbs);
    }

    const setBreadcrumbsFn = (crumbs: FilterItem[]) => {
        const c = crumbs.map(c => c.itemSelected || "");
        setBreadcrumbs(c.filter(Boolean)); // remove empty strings
    }

    const value: ArtifactDetailsContextType = {
        breadcrumbs: breadcrumbs,
        name: articleName,
        dateUpdated: articleModificationDate,
        setArtifactDetails: setArtifactDetails,
        setBreadcrumbsFromDynamicLayout: setBreadcrumbsFromDynamicLayout,
    }


    return <ArtifactDetailsContext.Provider value={value}>{children}</ArtifactDetailsContext.Provider>
}