import React, { useState } from 'react';
import { AuthProvider } from './AuthProvider';
import { NotificationsProvider } from './NotificationsProvider';
import { MbmProvider } from '@amzn/react-arb-tools';
import { PreferencesProvider } from './PreferencesProvider';
import { NavGraphProvider } from './NavGraphProvider';
import { TutorialsProvider } from './TutorialsProvider';
import { ArtifactDetailsProvider } from './ArticleDetailsProvider';
import { FavoritesProvider } from './FavoritesProvider';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DEFAULT_LOCALE } from 'src/constants';
import arbManifest from 'translations/arbManifest';
import { resolveBundleUrlWithFallbacks } from 'src/helpers';
import { RecentlyViewedProvider } from './RecentlyViewedProvider';
import { WelcomeModalProvider } from './WelcomeModalProvider';

interface ProvidersProps {
    children: React.ReactNode;
    setPreferencesOpen: (isOpen: boolean) => void;
}

export const localizationContextBuilder = new LocalizationContextBuilder()
    .withDefaultLocale(DEFAULT_LOCALE);

const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: localizationContextBuilder
        .withLocale(DEFAULT_LOCALE)
        .withDefaultLocale(DEFAULT_LOCALE)
        .build(),
    resolveBundleUrl: (
        resourcePackPath: string | undefined,
        locale: string,
        bundle: string
    ) => resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

export const Providers = ({ children, setPreferencesOpen }: ProvidersProps) => {


    return (
        <AuthProvider>
            <MbmProvider {...mbmOptions}>
                <NotificationsProvider>
                    <WelcomeModalProvider>
                        <PreferencesProvider setPreferencesOpen={setPreferencesOpen}>
                            <NavGraphProvider>
                                <TutorialsProvider>
                                    <ArtifactDetailsProvider>
                                        <FavoritesProvider>
                                            <RecentlyViewedProvider>
                                                {children}
                                            </RecentlyViewedProvider>
                                        </FavoritesProvider>
                                    </ArtifactDetailsProvider>
                                </TutorialsProvider>
                            </NavGraphProvider>
                        </PreferencesProvider>
                    </WelcomeModalProvider>
                </NotificationsProvider>
            </MbmProvider>
        </AuthProvider>
    );
}