import { Button, Icon, Link } from "@cloudscape-design/components";
import Footer from "src/components/Footer";
import Navigation from "src/components/Navigation";

import '../styles/learning-detail-page.scss';

const divStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  height: 'calc(100vh - 150px)',
  position: 'relative' as 'relative',
  fontFamily: 'var(--font-family-base-4om3hr, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif)',
  textAlign: 'center' as 'center',
};

const iconStyle = {
  paddingTop: '100px',
  height: '70px',
  lineHeight: '100px',
  textAlign: 'center' as 'center',
};

const NotFound = () => {
    return (
      <div>
        <div style={divStyle}>
          <div style={iconStyle} className="access-denied-icon-container">
            <Icon name="security" size="inherit" variant="error" />
          </div>
          <h1>{'Not Found'}</h1>
          <p>
            <div>
              {'We were not able to find that resource.'}
              <br />
              {'Please'} <Link href="https://river.amazon.com/?org=ARLExT">{'contact us'}</Link>{' '}
              {'or try again.'}
            </div>
          </p>
          <Button iconName="caret-left-filled" href="/">
            {'Go to homepage'}
          </Button>
        </div>
      </div>
    );
};

export default NotFound;