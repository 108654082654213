import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import QuickSearchImage from "src/images/tutorial-quick-search.jpg";

export const QuickSearchScreen = () => {
  const t = useTranslate();
  return (
    <SpaceBetween data-testid="welcome-gru-quick-search-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={QuickSearchImage} height={300} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_quick_search_description",
          "Another way to find content is with <b>Search</b>. Suggestions appear after 5 characters are typed. Select a suggestion to navigate directly to that content. You can also search for your entered text by pressing enter or selecting Search. Filters on the left of the search results allow you to filter results in specific categories.",
          { b: (chunk: string) => <strong key={chunk}>{chunk}</strong> }
        )}
      </Box>
    </SpaceBetween>
  );
};
