import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useApi } from "src/hooks/useApi";
import { PAGE } from "src/pages/enums";
import { Favorite } from "src/types/favorites";
import { FavoritesAddAndDeleteApiType, GetFavoritesApiType, PostPreferencesSelectionApiType, PreferencesApiType, PreferencesSelectionApiType } from "src/types/useApi";

type FavoriteIdsMapType = { [key: string]: boolean }

export interface FavoritesContextType {
    favorites: Favorite[];
    addFavorite: (id: string | number) => void;
    deleteFavorite: (id: string | number) => void;
    isFavorite: (id: string | number) => boolean;
}

export const FavoritesContext = React.createContext<FavoritesContextType>({
    favorites: [],
    addFavorite: () => { },
    deleteFavorite: () => { },
    isFavorite: () => false,
})

export const useFavorites = () => useContext(FavoritesContext)

interface FavoritesProviderProps {
    children: React.ReactNode;
}

export const FavoritesProvider = ({ children }: FavoritesProviderProps) => {

    const [favoriteIdsMap, setFavoriteIdsMap] = useState<FavoriteIdsMapType>({});
    const [needRefresh, setNeedRefresh] = useState(false);

    const { pathname } = useLocation();

    const { makeRequest: getAllFavorites, data: allFavoritesData }: GetFavoritesApiType = useApi({
        url: `user-profile/favorites`,
        method: 'GET',
    });

    const { makeRequest: makeAddFavoriteRequest, data: addFavoriteData }: FavoritesAddAndDeleteApiType = useApi({
        url: `user-profile/favorite/`,
        method: 'POST',
    });

    const { makeRequest: makeDeleteFavoriteRequest }: FavoritesAddAndDeleteApiType = useApi({
        url: `user-profile/favorite/`,
        method: 'DELETE',
    });

    const updateFavoriteIdsMap = (id: number | string) => setFavoriteIdsMap(favs => {
        favs[id] = !favs[id];
        return favs;
    })

    useEffect(() => {
        getAllFavorites();
    }, []);

    useEffect(() => {
        if(needRefresh){
            getAllFavorites();
        }
    }, [pathname]);

    useEffect(() => {
        if (allFavoritesData?.length) {
            const idsMap: FavoriteIdsMapType = {};
            allFavoritesData.forEach(item => idsMap[item.id] = true);
            setFavoriteIdsMap(idsMap);
            setNeedRefresh(false);
        }
    }, [allFavoritesData])

    useEffect(() => {
        if (addFavoriteData && !["/", `/${PAGE.FAVORITES}`].includes(pathname)) {
            getAllFavorites()
        }

    }, [addFavoriteData])

    const addFavorite = (id?: string | number) => {
        if (!id) return;
        updateFavoriteIdsMap(id);
        makeAddFavoriteRequest({ data: { id } });
        setNeedRefresh(true);
    };

    const deleteFavorite = (id?: string | number) => {
        if (!id) return;
        updateFavoriteIdsMap(id);
        makeDeleteFavoriteRequest({ url: `user-profile/favorite/${id}` });
        setNeedRefresh(true);
    };

    const isFavorite = (id?: string | number) => id ? !!favoriteIdsMap[id] : false;

    const value: FavoritesContextType = {
        favorites: allFavoritesData || [],
        addFavorite,
        deleteFavorite,
        isFavorite,
    }

    return <FavoritesContext.Provider value={value}>{children}</FavoritesContext.Provider>
}