import { Button, ExpandableSection, Link, SpaceBetween, Spinner, TextContent } from "@cloudscape-design/components";
import '../styles/dynamic-layout.scss';
import { ContentObjectsApiType } from "src/types/useApi";
import { useApi } from "src/hooks/useApi";
import { useEffect, useState } from "react";
import { DynamicLayoutContentObjects } from "src/types/contentObject";
import { FilterItem } from "./HierarchyFilters";
import { Item } from "./ExpandableContainer";
import { useLocation, useNavigate } from "react-router";
import { useTranslate } from "src/i18n/useTranslate";
import { useArtifactDetails } from "./providers/ArticleDetailsProvider";


interface DynamicLayoutProps {
    title?: string;
    dynamicDrilldownOptions?: FilterItem;
    dynamicLayoutIsArtifact: boolean;
    artifactId?: string;
    artifactVersionId?: string;
    dynamicArtifactImage?: string;
    filters: FilterItem[];
    setExploreModalOpen?: (isOpen: boolean) => void;
    modalVersion?: boolean;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
    handleDynamicItemSelection: (props: { documentId?: number, versionId?: number; path?: string, title?: string }) => void;
}

const DynamicLayout = ({ title, dynamicLayoutIsArtifact = false, artifactId = "", artifactVersionId = '', dynamicDrilldownOptions, dynamicArtifactImage = "", filters, modalVersion, setExploreModalOpen, setHasOpenedInNewTab, handleDynamicItemSelection}: DynamicLayoutProps) => {
    const navigate = useNavigate()

    const location = useLocation();
    const { pathname, hash } = location;

    const { setBreadcrumbsFromDynamicLayout } = useArtifactDetails();
    const t = useTranslate();
    const [artifactSections, setArtifactSections] = useState<Array<DynamicLayoutContentObjects>>([]);

    const { makeRequest: getArtifactSectionData, data: artifactSectionData, error, loading: artifactSectionDataLoading }: ContentObjectsApiType = useApi({
        url: `learning-artifacts/${artifactVersionId}/content-objects`,
        method: 'GET'
    });

    const navigateToDetails = (documentId: string) => {
        // set breadcrumbs
        if (setBreadcrumbsFromDynamicLayout) {
            const breadcrumbFilters = [...filters];

            if (dynamicLayoutIsArtifact) {
                breadcrumbFilters.pop();
            }

            setBreadcrumbsFromDynamicLayout(breadcrumbFilters)
        }

        // close explore modal
        if (modalVersion && setExploreModalOpen) {
            setExploreModalOpen(false);
        }

        setHasOpenedInNewTab(false);
        if (`/${documentId}` !== pathname || hash !== `#/${artifactSectionData[0].lcmsXmlGUID}`) {
            if (artifactSectionData.length) {
                navigate(`/${documentId}#/${artifactSectionData[0].lcmsXmlGUID}`)
            } else {
                navigate(`/${documentId}`)
            }
        }
    }

    const sectionClick = (section: Item) => {
        if (section.isDynamic) {
            handleDynamicItemSelection({documentId: section.documentId, versionId: section.versionId, path: section.path, title: section.title})
        } else {
            navigateToDetails(`${section.documentId}`)
        }
    }

    const imageClick = () => {
        if (dynamicLayoutIsArtifact) {
            navigateToDetails(artifactId)
        }
    }

    const closeModal = () => {
        // close explore modal
        if (modalVersion && setExploreModalOpen) {
            setExploreModalOpen(false);
        }
    }

    useEffect(() => {
        if (dynamicLayoutIsArtifact && artifactVersionId) {
            getArtifactSectionData({url: `learning-artifacts/${artifactVersionId}/content-objects`});
        }
    }, [dynamicLayoutIsArtifact, artifactId, artifactVersionId]);

    useEffect(() => {
        if (artifactSectionData) {
            setArtifactSections(artifactSectionData);
        }
    }, [artifactSectionData])

    return (
        <ExpandableSection
            variant="container"
            headerText={title}
            data-testid="expandable-dynamic-section"
            expanded={true}
        >
            <div className="dynamic-layout-content-container">
                <div className="image-container">
                    <Button variant="inline-link" onClick={imageClick} data-testid="dynamic-layout-image-button">
                        <img src={dynamicArtifactImage || `https://core-ar.bravais.com/api/v3/documents/${artifactId}/picture`} style={{ width: "100%", height: "200px" }} />
                    </Button>
                </div>
                <div className="info-container">
                    <SpaceBetween size="m">
                        <div className="header-container">
                            <TextContent>
                                {dynamicLayoutIsArtifact ? (
                                    <h3><Button variant="inline-link" onClick={imageClick} data-dynamic-layout-header-button>{title}</Button> {t('dynamic_layout_artifact_header', 'contains the following topics:')}</h3>
                                ) : (
                                    <h3>{t('dynamic_layout_parent_nav_header', 'Use the links below to access training and documentation for: ')} {title}</h3>
                                )}
                            </TextContent>
                        </div>
                        <div className="links-container">
                            <TextContent>
                                <ul>
                                    {dynamicLayoutIsArtifact ?
                                        !artifactSectionDataLoading ?
                                            artifactSectionData?.map((section: DynamicLayoutContentObjects) => {
                                                return (
                                                    <li
                                                        key={section.name + " " + section.lcmsXmlGUID}
                                                        className="dynamic-layout-artifact-link"
                                                        data-testid="dynamic-layout-section"
                                                    >
                                                        <Link
                                                            onFollow={() => {
                                                                setHasOpenedInNewTab(false);
                                                                closeModal();
                                                                navigate(`${artifactId}#/${section.lcmsXmlGUID}`);
                                                            }}
                                                            href=""
                                                        >{section.name}</Link>
                                                    </li>
                                                )
                                            })
                                            : (<Spinner size="normal" />)
                                        :
                                        dynamicDrilldownOptions?.items?.map((section) => {
                                            return (
                                                <li key={section.versionId}>
                                                    <Button
                                                        variant="inline-link"
                                                        data-dynamic-layout-navigation-link
                                                        data-testid="dynamic-layout-link"
                                                        onClick={() => sectionClick(section)}
                                                    >
                                                        {section.title}
                                                    </Button>
                                                </li>
                                            )
                                        })
                                    }

                                </ul>
                            </TextContent>
                        </div>
                    </SpaceBetween>
                </div>
            </div>
        </ExpandableSection>
    )
}

export default DynamicLayout;