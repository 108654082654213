import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import FeedbackImage from "src/images/tutorial-feedback.jpg";

export const FeedbackScreen = () => {
  const t = useTranslate();

  return (
    <SpaceBetween data-testid="welcome-gru-feedback-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={FeedbackImage} height={300} />
      </div>
      <Box variant="div">
        {t(
          "welcome_gru_feedback_description",
          "<p>To provide Feedback on page layout or content, select the icon in the top right corner of the page. Rate your experience with stars and leave your written comments.</p><p>For issues with GRU or to request content updates, click the Help button at the bottom of the page. This takes you to AR LExT's River where you can submit a ticket.</p>",
          { p: (chunk: string) => <p key={chunk}>{chunk}</p> }
        )}
      </Box>
    </SpaceBetween>
  );
};
