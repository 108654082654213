import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import { UseApiType } from "src/types/useApi";
import { createAxiosInstance } from "src/utils/axiosInstance";


const axios = createAxiosInstance();

/*

USAGE EXAMPLE

const { makeRequest, data, error, loading } = useApi({
        url: 'nav-graph',
        method: 'GET'
    });

*/

export const useApi = (request: AxiosRequestConfig): UseApiType => {
    const [data, setData] = useState();
    const [error, setError] = useState<Error | AxiosError>();
    const [loading, setLoading] = useState(false);

    const [currentRequest, setCurrentRequest] = useState(request);

    const makeRequest = async (requestOverride?: AxiosRequestConfig) => {
        let req = { ...currentRequest };

        if (requestOverride) {
            const newReq = {
                ...currentRequest,
                ...requestOverride
            };
            req = newReq;
            setCurrentRequest(newReq);
        }

        setLoading(true);
        setError(undefined);
        let response: any;
        try {
            response = await axios(req) as AxiosResponse;

            if (!response.data) throw new Error("No Data!");

            setData(response.data);
        } catch (err) {
            if (err instanceof AxiosError) {
                setError(err)
            } else {
                setError(err as Error)
            }
        } finally {
            setLoading(false);
            return response;
        }
    }



    return {
        makeRequest,
        data,
        loading,
        error,
        currentRequest,
    }

}