import { AppLayoutProps } from "@cloudscape-design/components";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { addMonthsToDate } from "src/helpers";
import { useApi } from "src/hooks/useApi";
import { TABS } from "src/tutorials/enums";
import { PostTutorialAutoLaunchDateApiType, TutorialAutoLaunchApiType } from "src/types/useApi";
import { useAuth } from "./AuthProvider";
import { useWelcomeModal } from "./WelcomeModalProvider";

interface TutorialsContextType {
    activeTabId: TABS;
    setActiveTabId: (id: TABS) => void;
    toolsOpen: boolean;
    setToolsOpen: (value: boolean) => void;
    helpPanelHandler: (key: string) => undefined;
    helpPanelTopic: string;
    appLayoutRef: React.MutableRefObject<AppLayoutProps.Ref | null>;
}

const TutorialsContext = React.createContext<TutorialsContextType>({
    activeTabId: TABS.TUTORIALS_PANEL,
    setActiveTabId: () => { },
    toolsOpen: false,
    setToolsOpen: () => { },
    helpPanelHandler: () => undefined,
    helpPanelTopic: 'default',
    appLayoutRef: {} as React.MutableRefObject<AppLayoutProps.Ref | null>,
})

export const useTutorials = () => useContext(TutorialsContext);

interface TutorialsProviderProps {
    children: React.ReactNode;
}

export const TutorialsProvider = ({ children }: TutorialsProviderProps) => {
    const [activeTabId, setActiveTabId] = useState<TABS>(TABS.TUTORIALS_PANEL);
    const [toolsOpen, setToolsOpen] = useState(false);
    const [helpPanelTopic, setHelpPanelTopic] = useState<string>('default');

    const appLayoutRef = useRef<AppLayoutProps.Ref>(null);

    const { isPrivacyRestricted } = useAuth();
    const { setWelcomeGruOpen, setPreferencesModalOpen } = useWelcomeModal();

    const { makeRequest: getTutorialAutoLaunchData, data: tutorialAutoLaunchData }: TutorialAutoLaunchApiType = useApi({
        method: "GET",
        url: "user-profile/tutorial-auto-launch-indicators"
    })

    const { makeRequest: postTutorialAutoLaunchDate }: PostTutorialAutoLaunchDateApiType = useApi({
        method: "POST",
        url: "user-profile/tutorial-launch-date"
    })

    const shouldLaunchTutorial = useCallback(() => {
        if (isPrivacyRestricted() || !tutorialAutoLaunchData) return false;

        return !tutorialAutoLaunchData.tutorialLaunchDate || new Date() > new Date(tutorialAutoLaunchData.tutorialLaunchDate);

    }, [isPrivacyRestricted, tutorialAutoLaunchData])

    useEffect(() => {
        getTutorialAutoLaunchData()
    }, []);


    useEffect(() => {
        if (shouldLaunchTutorial()) {
            const { tutorialLaunchDate } = tutorialAutoLaunchData;
            !tutorialLaunchDate ? setWelcomeGruOpen(true) : setPreferencesModalOpen(true);
            postTutorialAutoLaunchDate({
                data: {
                    tutorialLaunchDate: addMonthsToDate(tutorialLaunchDate ? new Date(tutorialLaunchDate) : new Date(), 6)
                }
            })
        }

    }, [tutorialAutoLaunchData]);

    const helpPanelHandler = useCallback((topic: string) => {
        setHelpPanelTopic(topic);
        setActiveTabId(TABS.HELP_PANEL);
        setToolsOpen(true);
        appLayoutRef.current?.focusToolsClose();
        return undefined;
    }, []);

    const value = {
        activeTabId,
        setActiveTabId,
        toolsOpen,
        setToolsOpen,
        appLayoutRef,
        helpPanelHandler,
        helpPanelTopic,
    }

    return (
        <TutorialsContext.Provider value={value}>
            {/* Annotation Context is required for hands-on tutorials. Since Hands-on tutorials are no longer being used, just commented it out, in case it would be enabled again in the future. */}
            {/* <AnnotationContext
                currentTutorial={currentTutorial}
                onStepChange={() => {
                    setActiveTabId(TABS.TUTORIALS_PANEL);
                    setHelpPanelTopic('default');
                }}
                onStartTutorial={event => {
                    const tutorial = event.detail.tutorial as TutorialProps;
                    setTutorialCompleted(tutorial.id, false);
                    setCurrentTutorialId(tutorial.id);
                }
                }
                onExitTutorial={() => { setCurrentTutorialId(null) }}
                onFinish={() => setTutorialCompleted(currentTutorialId!, true)}
                i18nStrings={annotationContextStrings()}
            > */}
                {children}
            {/* </AnnotationContext> */}
        </TutorialsContext.Provider>
    )
}