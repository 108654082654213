import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import FooterImage from "src/images/tutorial-footer.jpg";

export const FooterScreen = () => {
  const t = useTranslate();
  return (
    <SpaceBetween data-testid="welcome-gru-footer-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={FooterImage} width={780} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_footer_description",
          "At the bottom of every GRU page, a <b>Footer</b> menu provides a link to launch this GRU Tutorial, info about LExT, link to the GRU wiki, and a few links to get help or provide feedback.",
          { b: (chunk: string) => <strong key={chunk}>{chunk}</strong> }
        )}
      </Box>
    </SpaceBetween>
  );
};
