import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import ContentPageImage from "src/images/tutorial-content-page.jpg";

export const ContentPageScreen = () => {
  const t = useTranslate();

  return (
    <SpaceBetween data-testid="welcome-gru-content-page-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={ContentPageImage} height={300} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_content_page_description",
          "A <b>Content Page</b> is any page that displays content, like training content, hardware manuals, or eLearning. Each Content Page has a sidebar table of contents, and navigational buttons in the bottom right, which can be used to navigate through pages.",
          { b: (chunk: string) => <strong key={chunk}>{chunk}</strong> }
        )}
      </Box>
    </SpaceBetween>
  );
};
