var endpointMap = {
	af: {
		"pages.gru": "translation-af.json"
	},
	"af-ZA": {
		"pages.gru": "translation-af.json"
	},
	ar: {
		"pages.gru": "translation-ar.json"
	},
	"ar-AE": {
		"pages.gru": "translation-ar.json"
	},
	"ar-EG": {
		"pages.gru": "translation-ar.json"
	},
	"ar-SA": {
		"pages.gru": "translation-ar.json"
	},
	bg: {
		"pages.gru": "translation-bg.json"
	},
	"bg-BG": {
		"pages.gru": "translation-bg.json"
	},
	bn: {
		"pages.gru": "translation-bn.json"
	},
	"bn-IN": {
		"pages.gru": "translation-bn.json"
	},
	cs: {
		"pages.gru": "translation-cs.json"
	},
	"cs-CZ": {
		"pages.gru": "translation-cs.json"
	},
	de: {
		"pages.gru": "translation-de.json"
	},
	"de-DE": {
		"pages.gru": "translation-de.json"
	},
	en: {
		"pages.gru": "translation-en.json"
	},
	"en-AE": {
		"pages.gru": "translation-en.json"
	},
	"en-AU": {
		"pages.gru": "translation-en.json"
	},
	"en-CA": {
		"pages.gru": "translation-en.json"
	},
	"en-EG": {
		"pages.gru": "translation-en.json"
	},
	"en-GB": {
		"pages.gru": "translation-en.json"
	},
	"en-IN": {
		"pages.gru": "translation-en.json"
	},
	"en-SA": {
		"pages.gru": "translation-en.json"
	},
	"en-SG": {
		"pages.gru": "translation-en.json"
	},
	"en-US": {
		"pages.gru": "translation-en.json"
	},
	es: {
		"pages.gru": "translation-es.json"
	},
	"es-AR": {
		"pages.gru": "translation-es.json"
	},
	"es-CL": {
		"pages.gru": "translation-es.json"
	},
	"es-CO": {
		"pages.gru": "translation-es.json"
	},
	"es-ES": {
		"pages.gru": "translation-es.json"
	},
	"es-MX": {
		"pages.gru": "translation-es.json"
	},
	"es-US": {
		"pages.gru": "translation-es.json"
	},
	fa: {
		"pages.gru": "translation-fa.json"
	},
	"fa-AF": {
		"pages.gru": "translation-fa.json"
	},
	fil: {
		"pages.gru": "translation-fil.json"
	},
	fr: {
		"pages.gru": "translation-fr.json"
	},
	"fr-CA": {
		"pages.gru": "translation-fr.json"
	},
	"fr-FR": {
		"pages.gru": "translation-fr.json"
	},
	gu: {
		"pages.gru": "translation-gu.json"
	},
	"gu-IN": {
		"pages.gru": "translation-gu.json"
	},
	ha: {
		"pages.gru": "translation-ha.json"
	},
	"ha-NG": {
		"pages.gru": "translation-ha.json"
	},
	he: {
		"pages.gru": "translation-he.json"
	},
	"he-IL": {
		"pages.gru": "translation-he.json"
	},
	hi: {
		"pages.gru": "translation-hi.json"
	},
	"hi-IN": {
		"pages.gru": "translation-hi.json"
	},
	ht: {
		"pages.gru": "translation-ht.json"
	},
	"ht-HT": {
		"pages.gru": "translation-ht.json"
	},
	hu: {
		"pages.gru": "translation-hu.json"
	},
	"hu-HU": {
		"pages.gru": "translation-hu.json"
	},
	id: {
		"pages.gru": "translation-id.json"
	},
	"id-ID": {
		"pages.gru": "translation-id.json"
	},
	ig: {
		"pages.gru": "translation-ig.json"
	},
	"ig-NG": {
		"pages.gru": "translation-ig.json"
	},
	it: {
		"pages.gru": "translation-it.json"
	},
	"it-IT": {
		"pages.gru": "translation-it.json"
	},
	ja: {
		"pages.gru": "translation-ja.json"
	},
	"ja-JP": {
		"pages.gru": "translation-ja.json"
	},
	kn: {
		"pages.gru": "translation-kn.json"
	},
	"kn-IN": {
		"pages.gru": "translation-kn.json"
	},
	ko: {
		"pages.gru": "translation-ko.json"
	},
	"ko-KR": {
		"pages.gru": "translation-ko.json"
	},
	lt: {
		"pages.gru": "translation-lt.json"
	},
	"lt-LT": {
		"pages.gru": "translation-lt.json"
	},
	lv: {
		"pages.gru": "translation-lv.json"
	},
	"lv-LV": {
		"pages.gru": "translation-lv.json"
	},
	ml: {
		"pages.gru": "translation-ml.json"
	},
	"ml-IN": {
		"pages.gru": "translation-ml.json"
	},
	mr: {
		"pages.gru": "translation-mr.json"
	},
	"mr-IN": {
		"pages.gru": "translation-mr.json"
	},
	my: {
		"pages.gru": "translation-my.json"
	},
	"my-MM": {
		"pages.gru": "translation-my.json"
	},
	ne: {
		"pages.gru": "translation-ne.json"
	},
	"ne-NP": {
		"pages.gru": "translation-ne.json"
	},
	nl: {
		"pages.gru": "translation-nl.json"
	},
	"nl-NL": {
		"pages.gru": "translation-nl.json"
	},
	pa: {
		"pages.gru": "translation-pa.json"
	},
	"pa-IN": {
		"pages.gru": "translation-pa.json"
	},
	pl: {
		"pages.gru": "translation-pl.json"
	},
	"pl-PL": {
		"pages.gru": "translation-pl.json"
	},
	ps: {
		"pages.gru": "translation-ps.json"
	},
	"ps-AF": {
		"pages.gru": "translation-ps.json"
	},
	pt: {
		"pages.gru": "translation-pt.json"
	},
	"pt-BR": {
		"pages.gru": "translation-pt.json"
	},
	"pt-PT": {
		"pages.gru": "translation-pt.json"
	},
	ro: {
		"pages.gru": "translation-ro.json"
	},
	"ro-RO": {
		"pages.gru": "translation-ro.json"
	},
	ru: {
		"pages.gru": "translation-ru.json"
	},
	"ru-RU": {
		"pages.gru": "translation-ru.json"
	},
	sk: {
		"pages.gru": "translation-sk.json"
	},
	"sk-SK": {
		"pages.gru": "translation-sk.json"
	},
	sl: {
		"pages.gru": "translation-sl.json"
	},
	"sl-SI": {
		"pages.gru": "translation-sl.json"
	},
	so: {
		"pages.gru": "translation-so.json"
	},
	"so-SO": {
		"pages.gru": "translation-so.json"
	},
	sv: {
		"pages.gru": "translation-sv.json"
	},
	"sv-SE": {
		"pages.gru": "translation-sv.json"
	},
	ta: {
		"pages.gru": "translation-ta.json"
	},
	"ta-IN": {
		"pages.gru": "translation-ta.json"
	},
	te: {
		"pages.gru": "translation-te.json"
	},
	"te-IN": {
		"pages.gru": "translation-te.json"
	},
	ti: {
		"pages.gru": "translation-ti.json"
	},
	"ti-ER": {
		"pages.gru": "translation-ti.json"
	},
	"tl-PH": {
		"pages.gru": "translation-fil.json"
	},
	tr: {
		"pages.gru": "translation-tr.json"
	},
	"tr-TR": {
		"pages.gru": "translation-tr.json"
	},
	uk: {
		"pages.gru": "translation-uk.json"
	},
	"uk-UA": {
		"pages.gru": "translation-uk.json"
	},
	und: {
		"pages.gru": "translation.json"
	},
	ur: {
		"pages.gru": "translation-ur.json"
	},
	"ur-PK": {
		"pages.gru": "translation-ur.json"
	},
	vi: {
		"pages.gru": "translation-vi.json"
	},
	"vi-VN": {
		"pages.gru": "translation-vi.json"
	},
	xh: {
		"pages.gru": "translation-xh.json"
	},
	"xh-ZA": {
		"pages.gru": "translation-xh.json"
	},
	yo: {
		"pages.gru": "translation-yo.json"
	},
	"yo-NG": {
		"pages.gru": "translation-yo.json"
	},
	zh: {
		"pages.gru": "translation-zh.json"
	},
	"zh-CN": {
		"pages.gru": "translation-zh.json"
	},
	"zh-TW": {
		"pages.gru": "translation-zh.json"
	},
	zu: {
		"pages.gru": "translation-zu.json"
	},
	"zu-ZA": {
		"pages.gru": "translation-zu.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
