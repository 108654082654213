import { SideNavigationProps } from "@cloudscape-design/components"
import { NAV_GRAPH_ROOT_NODE_ID } from "src/components/providers/NavGraphProvider"
import { ENGLISH_LANGUAGE_NAME } from "src/constants"
import { ContentObjects } from "src/types/contentObject"
import { ArticleDetails } from "src/types/learningDetails"
import { Classification, FilterItem, FilterParams, NavGraph } from "src/types/navGraph"
import { PreferenceLocation, PreferenceSelection } from "src/types/preferences"
import { SearchResult } from "src/types/searchResult"

/* steps
- Get full navgraph
- Map through node children recursively
    - If current object has children, also map through those children

   filterChildrenByClassificationPath 
*/

const LANGUAGE_NAME_FILTER_VALUE_MAP: Record<PreferenceLocation['name'], string> = {
    "Arabic": "Arabic",
    "Czech": "Czech",
    "English (US)": "English",
    "French (Canada)": "Frenchcanadian",
    "French (France)": "French",
    "German": "German",
    "Italian": "Italian",
    "Japanese": "Japanese",
    "Polish": "Polish",
    "Spanish": "Spanish",
}

const checkLocationFilter = (
    filterParam: string,
    selectedUserPreferences: PreferenceSelection,
    shouldFilterDocuments: boolean = false
) => {
    const isMatchingLanguage = LANGUAGE_NAME_FILTER_VALUE_MAP[selectedUserPreferences.language.name] === filterParam;

    if (shouldFilterDocuments) {
        return isMatchingLanguage || ENGLISH_LANGUAGE_NAME.includes(filterParam);
    }
    return isMatchingLanguage;
}

export const doesPreferenceMatchFilter = (
    filterParams: FilterParams,
    selectedUserPreferences: PreferenceSelection,
    filterParamName: 'languages' | 'regions' | 'countries' | 'sites' | 'roles',
    userPreferenceName: 'language' | 'region' | 'country' | 'site' | 'role',
    shouldFilterDocuments: boolean
): boolean => {
    // if user selects all roles, and no roles in document = DO NOT SHOW
    if (
        filterParamName === "roles" &&
        selectedUserPreferences.role.name === "All Roles"
    ) {
            return true;
    }

    // if user has selected "All sites" = SHOW / do not filter by site at all
    if (
        filterParamName === "sites"
    ) {
        if(selectedUserPreferences.site?.name === "All sites" || !filterParams["sites"].length) {
            return true;
        }
    }

    // no site* selected in preferences, no site in document filters = SHOW
    if (!selectedUserPreferences[userPreferenceName] && !filterParams[filterParamName].length) {
        return true;
        // no site selected in preferences, site in document filters = SHOW (not true for role)
    } else if (!selectedUserPreferences[userPreferenceName] && filterParams[filterParamName].length) {
        return true;
        // site selected in preferences, no site in document filters = DO NOT SHOW
    } else if (selectedUserPreferences[userPreferenceName] && !filterParams[filterParamName].length) {
        return false;
    }

    // site selected in preferences,  same site in document filters = SHOW 
    // site selected in preferences,  different site in document filters = DO NOT SHOW 
    if (filterParamName === "languages") {
        return !!(filterParams[filterParamName] as string[]).filter((f: string) => checkLocationFilter(f, selectedUserPreferences, shouldFilterDocuments)).length;
    } else {
        return !!(filterParams[filterParamName] as FilterItem[]).filter((f: PreferenceLocation) => f.path === selectedUserPreferences[userPreferenceName]?.path).length;
    }

    //* site used as an example, but this is true for all filters
}

const getFilteredDocuments = (childDocuments: any, selectedUserPreferences: PreferenceSelection, shouldFilterDocuments: boolean) => {
    const documentNames = Object.keys(childDocuments);
    let filteredDocuments: any = {};

    documentNames.forEach((name: string) => {
        const filterParams = childDocuments[`${name}`].filterParams;


        const documentHasSelectedRegion = doesPreferenceMatchFilter(filterParams, selectedUserPreferences, 'regions', 'region', shouldFilterDocuments)
        const documentHasSelectedCountry = doesPreferenceMatchFilter(filterParams, selectedUserPreferences, 'countries', 'country', shouldFilterDocuments)
        const documentHasSelectedSite = doesPreferenceMatchFilter(filterParams, selectedUserPreferences, 'sites', 'site', shouldFilterDocuments)
        const documentHasSelectedRole = doesPreferenceMatchFilter(filterParams, selectedUserPreferences, 'roles', 'role', shouldFilterDocuments)
        const documentHasSelectedLanguage = doesPreferenceMatchFilter(filterParams, selectedUserPreferences, 'languages', 'language', shouldFilterDocuments)

        if (
            documentHasSelectedRegion &&
            documentHasSelectedCountry &&
            documentHasSelectedSite &&
            documentHasSelectedLanguage &&
            documentHasSelectedRole
        ) {
            filteredDocuments[name] = childDocuments[`${name}`];
        }
    })

    return filteredDocuments;
}

const filterChildrenByClassificationPath = (navGraphBlock: any, selectedUserPreferences: PreferenceSelection, shouldFilterDocuments: boolean) => {
    if (navGraphBlock && navGraphBlock.children) {
        const navGraphBlockKeys = Object.keys(navGraphBlock?.children);

        navGraphBlockKeys.forEach(block => {
            if (navGraphBlock.children[block]?.documents) {
                if (navGraphBlock.documents) {
                    const filteredParentDocuments = getFilteredDocuments(navGraphBlock.documents, selectedUserPreferences, shouldFilterDocuments);
                    navGraphBlock.documents = filteredParentDocuments;
                }
    
                const filteredBlockDocuments = getFilteredDocuments(navGraphBlock.children[block].documents, selectedUserPreferences, shouldFilterDocuments);
                navGraphBlock.children[block].documents = filteredBlockDocuments;
            }
    
            if (navGraphBlock.children[block]?.children) {
                filterChildrenByClassificationPath(navGraphBlock.children[block], selectedUserPreferences, shouldFilterDocuments)
            }
        })
    }
}

export const removeEmptyGraphBlocks = (graph: NavGraph) => {
    let keepRunning = false;

    Object.keys(graph).forEach(key => {
        if (!Object.keys(graph[key]?.documents || {}).length && !Object.keys(graph[key]?.children || {}).length) {
            delete graph[key];
            keepRunning = true;
        } else if (Object.keys(graph[key]?.children || {}).length) {
            removeEmptyGraphBlocks(graph[key].children!)
        }
    })

    if (keepRunning) {
        removeEmptyGraphBlocks(graph);
    }
}

export const cleanNavGraph = (navGraph: NavGraph) => {
    removeEmptyGraphBlocks(navGraph);
    Object.keys(navGraph).forEach(key => {
        if (!Object.keys(navGraph[key]?.documents || {}).length && !Object.keys(navGraph[key]?.children || {}).length) {
            delete navGraph[key];
        }
    })
}

export const getFilteredNavGraph = (fullNavGraph: NavGraph, selectedUserPreferences: PreferenceSelection, shouldFilterDocuments: boolean) => {
    if (fullNavGraph) {
        const navGraphKeys = Object.keys(fullNavGraph);

        navGraphKeys.forEach(key => {
            return filterChildrenByClassificationPath(fullNavGraph[key], selectedUserPreferences, shouldFilterDocuments);
        })

        cleanNavGraph(fullNavGraph);

    }

}

export const buildBreadcrumbs = (articleData: ArticleDetails | SearchResult, navGraph?: NavGraph) => {
    // find classification that starts with 357 since thats our navgraph path
    const correctClassification = articleData?.classifications.find(cl => cl.path.startsWith(`${NAV_GRAPH_ROOT_NODE_ID}/`))

    // classification path split by / ex: 357/358/359/361/ => ['357', '358', '359', '361', '']
    const splitClassifications = correctClassification?.path.split("/");

    // remove 357 from array since it's the entire navgraph and remove empty string from end of array
    splitClassifications?.pop();
    splitClassifications?.splice(0, 1);

    const breadcrumbNames = [] as string[];

    /*
        splitClassificationIndex: index of child ID in splitClassifications array 
        childrenCollection: collection of navGraph nodes to look through for path piece at splitClassificationIndex
    */
    const addBreadcrumbFromPath = (splitClassificationIndex: number, childrenCollection: any) => {
        if (navGraph && splitClassifications?.length) {
            const child = childrenCollection[splitClassifications[splitClassificationIndex]];

            breadcrumbNames.push(child?.name)

            if (child?.children && splitClassificationIndex < splitClassifications.length + 1) {
                const childrenOfChild = child?.children;
                addBreadcrumbFromPath(splitClassificationIndex + 1, childrenOfChild)
            }
        }
    }

    addBreadcrumbFromPath(0, navGraph);
    return breadcrumbNames;
}

export const formatSubtopicTitle = (title: string): string => {
    const removeSpaces = title.replace(/ /g, '-');
    const removedIllegalChars = removeSpaces.replace(/[^a-zA-Z0-9-_]/g, '');
    const truncated = removedIllegalChars.substring(0, 80);
    return truncated;
}

export const buildDetailsSideNav = (contentObjects: ContentObjects[], isChild = false): SideNavigationProps.Item[] => {
    const sideNavStructure = contentObjects.map(co => {
        const sideNavSection = isChild && !co.topics?.length ? {
            text: co.name,
            href: `#/${co.lcmsXmlGUID}/${formatSubtopicTitle(co.name)}`,
            type: co.topics?.length ? "expandable-link-group" : "link",
            items: co.topics?.length ? buildDetailsSideNav(co.topics, true) : undefined
        } : {
            text: co.name,
            type: co.topics?.length ? "expandable-link-group" : "link",
            items: co.topics?.length ? buildDetailsSideNav(co.topics, true) : undefined
        }

        if (!sideNavSection.items || !sideNavSection.items.length) {
            delete sideNavSection.items;
        }

        return sideNavSection;
    }) as SideNavigationProps.Item[];
    return sideNavStructure;

}

export const buildHrefMap = (contentObjects: ContentObjects[]): Map<string, string> => {
    const hrefs = contentObjects.map(co => {
        const href = `#/${co.lcmsXmlGUID}/${formatSubtopicTitle(co.name)}`;
        if (co.topics?.length)  {
            return buildHrefMap(co.topics)
        } else {
            return new Map([[co.lcmsXmlGUID, `#/${co.lcmsXmlGUID}/${formatSubtopicTitle(co.name)}`]])
        }
    });
    return hrefs.reduce((map1, map2) => new Map([...Array.from(map1.entries()), ...Array.from(map2.entries())]))
}

export const buildOrderedSubtopicList = (articleData: ArticleDetails): string[] => {
    const answer: string[] = [];

    if (articleData.contentObjects.lessons?.length) {
        const dataContentObjects = articleData.contentObjects.lessons;

        const addTopicIdsToListInOrder = (cos: ContentObjects[]) => {
            cos.forEach(co => {
                if (co.topics?.length) {
                    addTopicIdsToListInOrder(co.topics);
                } else {
                    answer.push(co.lcmsXmlGUID);
                }
            })
        }

        addTopicIdsToListInOrder(dataContentObjects);
        return answer;
    }

    return [];
}