import Content from "src/components/Content";
import { AppLayoutWrapper } from "./AppLayoutWrapper";
import Search from "src/components/Search";

interface HomePageProps{
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

const Home = ({
    setHasOpenedInNewTab,
}: HomePageProps) => {

    const content = (
        <div style={{  minHeight: 'calc(100vh - 150px)',}}> 
            <Search setHasOpenedInNewTab={setHasOpenedInNewTab}/>
            <Content setHasOpenedInNewTab={setHasOpenedInNewTab} />
        </div>
    );

    return (
        <div data-home-page>
            <AppLayoutWrapper content={content} />
        </div>
    );
};

export default Home;