import { Box, SpaceBetween } from "@cloudscape-design/components";
import { chunk } from "lodash";
import { useTranslate } from "src/i18n/useTranslate";
import PersistentUrlImage from "src/images/tutorial-persistent-url.jpg";

export const PersistentUrlScreen = () => {
  const t = useTranslate();

  return (
    <SpaceBetween data-testid="welcome-gru-persistent-url-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={PersistentUrlImage} height={300} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_persistent_url_description",
          "At the top of all content pages, we can see a <b>Persistent URL</b>, which is a unique and static URL that can be easily shared or bookmarked. Depending on the topic being viewed, the URL changes to include the title of that topic.",
          { b: (chunk: string) => <strong key={chunk}>{chunk}</strong> }
        )}
      </Box>
    </SpaceBetween>
  );
};
