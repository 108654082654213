import Alert from "@cloudscape-design/components/alert";
import Box from "@cloudscape-design/components/box";
import Link from "@cloudscape-design/components/link";
import { useTranslate } from "src/i18n/useTranslate";
import { useWelcomeModal } from "./providers/WelcomeModalProvider";

export const InfoBanner = () => {
  const t = useTranslate();
  const { setWelcomeGruOpen } = useWelcomeModal();

  return (
    <Box margin={"m"} padding={{ horizontal: "xxxl" }}>
      <Alert
        statusIconAriaLabel={t("aria-label-info-icon", "Info")}
        header={t("info-banner-header", "Welcome to GRU!")}
      >
        {t(
          "info-banner-content",
          "To learn more, launch the <tutorial-link>GRU tutorial</tutorial-link>. You can find helpful links at the bottom of the page. Use the AR LExT RIVER intake to <issue-link>report a GRU issue or feature request</issue-link> or <update-link>content update request</update-link>. To learn more, visit <wiki-link>the GRU wiki</wiki-link> or join the <slack-link></slack-link> Slack channel.",
          {
            "tutorial-link": (chunk: string) => (
              <Link
                key="tutorial-link"
                href="#"
                onFollow={() => {
                  setWelcomeGruOpen(true);
                  window.scrollTo({ top: 0 });
                }}
              >
                {chunk}
              </Link>
            ),
            "issue-link": (chunk: string) => (
              <Link
                key="issue-link"
                href="https://river.amazon.com/?org=ARLExT&buildingId=BOS12&q0=23682267-25a3-4faa-b4a9-5ceb65d66c60&q1=81cc7c0c-8f26-4679-a67c-680932334103"
                target="_blank"
              >
                {chunk}
              </Link>
            ),
            "update-link": (chunk: string) => (
              <Link
                key="update-link"
                href="https://river.amazon.com/?org=ARLExT"
                target="_blank"
              >
                {chunk}
              </Link>
            ),
            "wiki-link": (chunk: string) => (
              <Link
                key="wiki-link"
                href="https://w.amazon.com/bin/view/Amazon_Robotics/BOS/LExT/GRU/"
                target="_blank"
              >
                {chunk}
              </Link>
            ),
            "slack-link": () => (
              <Link
                key="slack-link"
                href="https://amazon.enterprise.slack.com/archives/C07L7GWGPKN"
                target="_blank"
              >
                #gru-interest
              </Link>
            ),
          }
        )}
      </Alert>
    </Box>
  );
};
