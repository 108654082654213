import PreferencesPane from "src/components/PreferencesPane";
import Footer from "./Footer";
import Navigation from "./Navigation";
import React, { useState } from "react";
import ExploreModal from "./ExploreModal";
import { AppRoutes } from "./Routes";
import { Providers } from './providers';
import { WelcomeGruModal } from "./WelcomeGruModal";
import { PreferencesModal } from "./PreferencesModal";

const App = () => {
    const [exploreModalOpen, setExploreModalOpen] = useState(false);
    const [preferencesOpen, setPreferencesOpen] = useState(false);
    const [hasOpenedInNewTab, setHasOpenedInNewTab] = useState<boolean>(false);
    return (
        <Providers setPreferencesOpen={setPreferencesOpen}>
            <Navigation
                setPreferencesOpen={setPreferencesOpen}
                setExploreModalOpen={setExploreModalOpen}
                setHasOpenedInNewTab={setHasOpenedInNewTab}
            />
            <PreferencesPane
                preferencesOpen={preferencesOpen}
            />
            <ExploreModal
                setExploreModalOpen={setExploreModalOpen}
                exploreModalOpen={exploreModalOpen}
                setHasOpenedInNewTab={setHasOpenedInNewTab}
            />
            <PreferencesModal setUserPreferencesPaneOpen={setPreferencesOpen} />
            <WelcomeGruModal/>
            <AppRoutes 
                hasOpenedInNewTab={hasOpenedInNewTab} 
                setHasOpenedInNewTab={setHasOpenedInNewTab}
            />
            <Footer />
        </Providers>
    );
};

export default App;
