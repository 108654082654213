import React, { useCallback, useContext, useEffect, useState } from "react";
import { fetchAuthSession, signInWithRedirect, signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import LoadingSpinner from "../LoadingSpinner";
import { getPrivacyRestrictedCountries } from "src/helpers";
import { isTablet, isMobile, browserName } from 'react-device-detect';
import initialMetricsPublisher from "src/metrics";
import * as KatalMetrics from '@amzn/katal-metrics';

export interface User {
    id: string
    name: string
    country: string
    hashedUserId: string
}

export interface AuthContextType {
    user?: User;
    isPrivacyRestricted: () => boolean;
    tokenRefreshed: boolean;
}

export const AuthContext = React.createContext<AuthContextType>({isPrivacyRestricted: () => true, tokenRefreshed: false})

export const useAuth = () => useContext(AuthContext)

interface AuthProviderProps {
    children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState<User>();
    const [tokenRefreshed, setTokenRefreshed] = useState(false);

    const deviceMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('Device');

    const fetchUser = async () => {
        try {
            const { tokens: session } = await fetchAuthSession()

            //  Check if there is a valid session
            if (!session?.idToken) throw new Error("Cannot retrieve idToken");

            if(!session?.idToken.payload['custom:userId']){
                //  this property is being used as UID in Dynamo DB, just in case if token payload doesn't have it, user is being signed out, then redirected to login.
                signOut();
                throw new Error("no user id found!")
            }

            const data = new TextEncoder().encode(String(session?.idToken.payload['custom:userId']));
            const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray
                .map((b) => b.toString(16).padStart(2, "0"))
                .join(""); 

            setUser({
                id: String(session?.idToken?.payload.sub),
                name: String(session?.idToken?.payload.given_name),
                country: String(session?.idToken.payload['custom:country']),
                hashedUserId: hashHex
            })

            if (isTablet) {
                deviceMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.device', 'Tablet'))
            } else {
                // laptop/desktop or mobile
                deviceMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.device', isMobile ? 'Mobile': 'Laptop'))
                deviceMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.browser', browserName))
            }
            deviceMetricsPublisher.publish(new KatalMetrics.Metric.String('gru.device.user', hashHex))
        } catch (error) {
            console.log("fetchUser error: ", error)

            //  redirect to Midway for SignIn
            signInWithRedirect({
                provider: {
                    custom: "FederateSignIn"
                }
            })
        }
    }

    useEffect(() => {
        fetchUser()

        const unsubscribe = Hub.listen("auth", ({ payload }) => {
            switch (payload.event) {
                case "signInWithRedirect":
                    fetchUser()
                    break;
                case "signedIn":
                case "tokenRefresh":
                    setTokenRefreshed(true);
                    break;
                default:
                    console.log(payload)
            }
        });

        return unsubscribe;
    }, []);

    const isPrivacyRestricted = useCallback(() => {
        return !user || !user.country || getPrivacyRestrictedCountries().includes(user.country);
    }, [user]);

    const value: AuthContextType = {
        user,
        isPrivacyRestricted,
        tokenRefreshed
    }

    if (!user) {
        return <div data-testid="full-page-centered-spinner">
            <LoadingSpinner />
        </div>
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}