import translationaf from './translation-af.json';
import translationar from './translation-ar.json';
import translationbg from './translation-bg.json';
import translationbn from './translation-bn.json';
import translationcs from './translation-cs.json';
import translationde from './translation-de.json';
import translationen from './translation-en.json';
import translationes from './translation-es.json';
import translationfa from './translation-fa.json';
import translationfil from './translation-fil.json';
import translationfr from './translation-fr.json';
import translationgu from './translation-gu.json';
import translationha from './translation-ha.json';
import translationhe from './translation-he.json';
import translationhi from './translation-hi.json';
import translationht from './translation-ht.json';
import translationhu from './translation-hu.json';
import translationid from './translation-id.json';
import translationig from './translation-ig.json';
import translationit from './translation-it.json';
import translationja from './translation-ja.json';
import translationkn from './translation-kn.json';
import translationko from './translation-ko.json';
import translationlt from './translation-lt.json';
import translationlv from './translation-lv.json';
import translationml from './translation-ml.json';
import translationmr from './translation-mr.json';
import translationmy from './translation-my.json';
import translationne from './translation-ne.json';
import translationnl from './translation-nl.json';
import translationpa from './translation-pa.json';
import translationpl from './translation-pl.json';
import translationps from './translation-ps.json';
import translationpt from './translation-pt.json';
import translationro from './translation-ro.json';
import translationru from './translation-ru.json';
import translationsk from './translation-sk.json';
import translationsl from './translation-sl.json';
import translationso from './translation-so.json';
import translationsv from './translation-sv.json';
import translationta from './translation-ta.json';
import translationte from './translation-te.json';
import translationti from './translation-ti.json';
import translationtr from './translation-tr.json';
import translationuk from './translation-uk.json';
import translationur from './translation-ur.json';
import translationvi from './translation-vi.json';
import translationxh from './translation-xh.json';
import translationyo from './translation-yo.json';
import translationzh from './translation-zh.json';
import translationzu from './translation-zu.json';
import translation from './translation.json';

// webpack transforms these JSON imports to resource URLs, so we cast our export accordingly here.
const i18nEndPointMap: Record<string, string> = {
  'translation-af.json': translationaf as unknown as string,
  'translation-ar.json': translationar as unknown as string,
  'translation-bg.json': translationbg as unknown as string,
  'translation-bn.json': translationbn as unknown as string,
  'translation-cs.json': translationcs as unknown as string,
  'translation-de.json': translationde as unknown as string,
  'translation-en.json': translationen as unknown as string,
  'translation-es.json': translationes as unknown as string,
  'translation-fa.json': translationfa as unknown as string,
  'translation-fil.json': translationfil as unknown as string,
  'translation-fr.json': translationfr as unknown as string,
  'translation-gu.json': translationgu as unknown as string,
  'translation-ha.json': translationha as unknown as string,
  'translation-he.json': translationhe as unknown as string,
  'translation-hi.json': translationhi as unknown as string,
  'translation-ht.json': translationht as unknown as string,
  'translation-hu.json': translationhu as unknown as string,
  'translation-id.json': translationid as unknown as string,
  'translation-ig.json': translationig as unknown as string,
  'translation-it.json': translationit as unknown as string,
  'translation-ja.json': translationja as unknown as string,
  'translation-kn.json': translationkn as unknown as string,
  'translation-ko.json': translationko as unknown as string,
  'translation-lt.json': translationlt as unknown as string,
  'translation-lv.json': translationlv as unknown as string,
  'translation-ml.json': translationml as unknown as string,
  'translation-mr.json': translationmr as unknown as string,
  'translation-my.json': translationmy as unknown as string,
  'translation-ne.json': translationne as unknown as string,
  'translation-nl.json': translationnl as unknown as string,
  'translation-pa.json': translationpa as unknown as string,
  'translation-pl.json': translationpl as unknown as string,
  'translation-ps.json': translationps as unknown as string,
  'translation-pt.json': translationpt as unknown as string,
  'translation-ro.json': translationro as unknown as string,
  'translation-ru.json': translationru as unknown as string,
  'translation-sk.json': translationsk as unknown as string,
  'translation-sl.json': translationsl as unknown as string,
  'translation-so.json': translationso as unknown as string,
  'translation-sv.json': translationsv as unknown as string,
  'translation-ta.json': translationta as unknown as string,
  'translation-te.json': translationte as unknown as string,
  'translation-ti.json': translationti as unknown as string,
  'translation-tr.json': translationtr as unknown as string,
  'translation-uk.json': translationuk as unknown as string,
  'translation-ur.json': translationur as unknown as string,
  'translation-vi.json': translationvi as unknown as string,
  'translation-xh.json': translationxh as unknown as string,
  'translation-yo.json': translationyo as unknown as string,
  'translation-zh.json': translationzh as unknown as string,
  'translation-zu.json': translationzu as unknown as string,
  'translation.json': translation as unknown as string
}

export default i18nEndPointMap;
