import React from "react";
import Box from '@cloudscape-design/components/box';
import Toggle from "@cloudscape-design/components/toggle";
import HierarchyFilters from "./HierarchyFilters"
import SpaceBetween from "@cloudscape-design/components/space-between";
import { useTranslate } from "src/i18n/useTranslate";
import { usePreferences } from "./providers/PreferencesProvider";
import { Hotspot } from "@cloudscape-design/components";
import { HOT_SPOTS } from "src/tutorials/enums";
import { useFavorites } from "./providers/FavoritesProvider";
import { ArtifactsSection } from "./ArtifactsSection";
import { PAGE } from "src/pages/enums";
import { useRecentlyViewed } from "./providers/RecentlyViewedProvider";

interface ContentProps {
    modalVersion?: boolean;
    setExploreModalOpen?: (isOpen: boolean) => void;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

const Content = ({ modalVersion = false, setExploreModalOpen, setHasOpenedInNewTab, }: ContentProps) => {
    const t = useTranslate();

    const { refinementToggleOn } = usePreferences();
    const { favorites } = useFavorites();
    const { recentlyViewed } = useRecentlyViewed();

    return (
        <div className={!modalVersion ? "main-content" : "explore-modal-content"}>
            <SpaceBetween size="m">
                <div className="flex-row justify-space-between">
                    <Hotspot hotspotId={HOT_SPOTS.GRU_CONTENT_BROWSE}>

                        <Box variant="h2" data-testid="header-text" >{t('home_expandable_heading', 'Explore GRU content.')}</Box>
                    </Hotspot>
                </div>
                <HierarchyFilters modalVersion={modalVersion} setExploreModalOpen={setExploreModalOpen} filterToggleChecked={refinementToggleOn} setHasOpenedInNewTab={setHasOpenedInNewTab} />
                {
                    !modalVersion && (
                        <>
                            <ArtifactsSection
                                artifacts={recentlyViewed.map(recent => ({ id: recent.id, ...recent.cardDetails! }))}
                                name={t('landing_page_recently_view', 'Recently Viewed')}
                                viewAllPath={`/recent`}
                            />

                            <ArtifactsSection
                                artifacts={favorites.map(fav => ({ id: fav.id, ...fav.cardDetails! }))}
                                name={t("landing_page_recent_favorites", "Recent Favorites")}
                                viewAllPath={`/${PAGE.FAVORITES}`}
                            />
                        </>
                    )
                }
            </SpaceBetween>
        </div>
    )
}

export default Content;