import { NavGraph } from "src/types/navGraph";
import { SearchResult, SearchResultsForCards } from "src/types/searchResult";
import { buildBreadcrumbs } from "./hierarchyUtils";
import { PreferenceSelection } from "src/types/preferences";
import { ChosenSearchClassification } from "src/types/search";

export const NA_PATH = "187/198/";
export const USA_PATH = "187/198/802/";
export const ENGLISH_PATH = "en-us";

export const SEARCH_LANGUAGE_MAP = {
    "Arabic": "arabic",
    "Czech": "czech",
    "English (US)": "english",
    "French (Canada)": "frenchcanadian",
    "French (France)": "french",
    "German": "german",
    "Italian": "italian",
    "Japanese": "japanese",
    "Polish": "polish",
    "Spanish": "spanish",
}

export const EXTENDED_SEARCH_PATHS = {
    REFINE_TOGGLE: "refine_toggle",
    ENGLISH_DOCUMENTS: "english_documents",
    USA_DOCUMENTS: "usa_documents"
};

export const formatSearchItemsForCards = (resultsFromSearch: SearchResult[], navGraph?: NavGraph): SearchResultsForCards[] => 
    resultsFromSearch.map(r => {
        const crumbsFromNavGraph = buildBreadcrumbs(r, navGraph);
        let updatedDate = new Date(r.dateUpdated)
        const options = { year: "numeric" as "numeric", month: 'short' as "short", day: "numeric" as "numeric" };
        let formattedDateString = updatedDate.toLocaleDateString("en-GB", options);
        // Handling Snippets as we may get multiple 'languageSpecific' properties
        // (like, languageSpecific_english, languageSpecific_german etc.). We have to
        // combine all of them and show it as part of our description. This will be
        // significant when we are able to get search results filtered based on language
        let descriptions: string[] = [];
        const snippetKeys = Object.keys(r.snippets).filter(snip => snip.startsWith('languageSpecific_'));
        snippetKeys.forEach(key => descriptions.push(...r.snippets[key]));

        return {
            name: r.documentName + " : " + r.name,
            description: descriptions?.join(" "),
            path: crumbsFromNavGraph.join(" > "),
            locale: r.languages,
            dateUpdated: formattedDateString,
            id: r.documentId,
            contentObjectId: r.contentObjectId,
            lcmsXmlGuid: r.lcmsXmlGuid,
        }
    }
)

export const filterSearchResultsByLocale = (searchResult: SearchResult, selectedUserPreferences: PreferenceSelection, includeUSADocuments: boolean): boolean => {
    let regionFound = false;
    let countryFound = false;
    let siteFound = false;

    const classificationsToSearch = searchResult.classifications;

    if(selectedUserPreferences.region) {
        regionFound = classificationsToSearch.findIndex((classification: any) => {
            return (classification.path === selectedUserPreferences.region?.path) || (includeUSADocuments && classification.path === NA_PATH);
        }) !== -1;

        // if no country, don't filter by country
        if(!selectedUserPreferences.country) {
            countryFound = true;
        }

        // if no site or site is set to "All Sites", don't filter by site 
        if(!selectedUserPreferences.site || selectedUserPreferences.site?.path !== "0/") {
            siteFound = true;
        }
    }

    if(selectedUserPreferences.country) {
        
        // since we have a country selected, no need to filter by region
        regionFound = true;

        // if we have site and site IS NOT set to all sites, dont sort by country
        if(selectedUserPreferences.site && selectedUserPreferences.site?.path !== "0/" ) {
            countryFound = true;
        } else {
            countryFound = classificationsToSearch.findIndex((classification: any) => {
                return (classification.path === selectedUserPreferences.country?.path) || (includeUSADocuments && classification.path === USA_PATH);
            }) !== -1;
        } 
    }

    if(selectedUserPreferences.site) {
        // if site is set to all sites, dont filter by site
        if(selectedUserPreferences.site.path === "0/") {
            siteFound = true;
        } else {
            siteFound = classificationsToSearch.findIndex((classification: any) => {
                return classification.path === selectedUserPreferences.site?.path || includeUSADocuments;
            }) !== -1;
        }
    }

    return regionFound && countryFound && siteFound;
}

export const filterSearchResultsByRole = (searchResult: SearchResult, selectedUserPreferences: PreferenceSelection): boolean => {
    let roleFound = false;

    const classificationsToSearch = searchResult.classifications;

    if(selectedUserPreferences.role) {
        // if role is set to all roles, don't sort by role
        if(selectedUserPreferences.role.path === "0/") {
            return true;
        } else {
            roleFound = classificationsToSearch.findIndex((classification: any) => {
                return classification.path === selectedUserPreferences.role?.path;
            }) !== -1;
        }
    }

    return roleFound;
}

export const filterSearchResultsByLanguage = (searchResult: SearchResult, selectedUserPreferences: PreferenceSelection, includeEnglishDocuments: boolean): boolean => {
    let languagefound = false;

    if(selectedUserPreferences.language) {
        languagefound = selectedUserPreferences.language.name.toLowerCase().includes(searchResult.languages.toLowerCase()) || (includeEnglishDocuments && searchResult.languages.toLowerCase().includes("english"));
    }

    return languagefound;
}

export const checkSelectedClassificationsOnlyContainExtendedSearch = (selectedClassifications: ChosenSearchClassification[]): boolean => {
    let hasAtLeastOneNonExtendedSearchFilterSelected = false;
    selectedClassifications.forEach(cl => {
        if(!Object.values(EXTENDED_SEARCH_PATHS).includes(cl.path)) {
            hasAtLeastOneNonExtendedSearchFilterSelected = true;
        }
    })
    return !hasAtLeastOneNonExtendedSearchFilterSelected;
    
}

export const filterSearchResultsByClassification = (searchResult: SearchResult, selectedClassifications: ChosenSearchClassification[]): boolean => {
    let classificationFound = false;

    const searchResultClassifications = searchResult.classifications;

    const selectedClassificationsOnlyContainExtendedSearch = checkSelectedClassificationsOnlyContainExtendedSearch(selectedClassifications);

    if(selectedClassifications.length && !selectedClassificationsOnlyContainExtendedSearch) {
        selectedClassifications.forEach((classification: ChosenSearchClassification) => {
            searchResultClassifications.forEach((searchClassification: ChosenSearchClassification) => {
                if(searchClassification.path.includes(classification.path)) {
                    classificationFound = true;
                }
            });
        });
    } else {
        classificationFound = true;
    }

    return classificationFound;
}

export const filterSearchResults = (
    resultsFromSearch: SearchResult[],
     selectedUserPreferences: PreferenceSelection,
     selectedClassifications: ChosenSearchClassification[],
     refinementToggleOn: boolean
): SearchResult[] => {
    // "Floor" resultsFromSearch?.[0] = NA with no country
    // "Floor" resultsFromSearch?.[6] = EU with country
    // "Aether" resultsFromSearch?.[4] = NA with country and site
    // "Floor Access" resultsFromSearch?.[5] = NA with no country no site, Ar Corporate and RME
    // const nodeToSearch = resultsFromSearch?.[4];

    const filteredResults = resultsFromSearch.filter(nodeToSearch => {
        const localeMatch = filterSearchResultsByLocale(nodeToSearch, selectedUserPreferences, false);
        const roleMatch = filterSearchResultsByRole(nodeToSearch, selectedUserPreferences);
        const languageMatch = filterSearchResultsByLanguage(nodeToSearch, selectedUserPreferences, refinementToggleOn);
        const classificationMatch = filterSearchResultsByClassification(nodeToSearch, selectedClassifications);
    
        if(localeMatch && roleMatch && languageMatch && classificationMatch) {
            return nodeToSearch;
        }
    })

    return filteredResults;
}

