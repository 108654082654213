export enum HOT_SPOTS  {
    FILTER_TOGGLE = "filter-toggle",
    GRU_CONTENT_BROWSE = "gru-content-browse",
    TOP_NAV_BAR_WELCOME = "top-navigation-bar-welcome",
    TOP_NAV_BAR_USER_PREFERENCES = "top-navigation-bar-user-preferences",
    TOP_NAV_BAR_UPDATED_PREFERENCES = "top-navigation-bar-updated-preferences",
    PREFERENCES_SAVE_BUTTON = "preferences-save-button",
    PREFERENCES_REGION = "preferences-region",
    PREFERENCES_COUNTRY = "preferences-country",
    PREFERENCES_SITE = "preferences-site",
    PREFERENCES_ROLE = "preferences-role",
    PREFERENCES_LANGUAGE = "preferences-language",
}

export enum TABS {
    HELP_PANEL = 'help-panel',
    TUTORIALS_PANEL = 'tutorials-panel',
}

export enum TUTORIALS {
    USER_PREFERENCES = 'user-preferences'
}