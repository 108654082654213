import { ResourcesConfig } from "@aws-amplify/core";

const config: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolId: "us-west-2_OyyPmw9sS",
            userPoolClientId: "60m214oeju90a7f65e3a7vgp7t",
            loginWith: {
                oauth: {
                    domain: "gru-web-gamma.auth.us-west-2.amazoncognito.com",
                    redirectSignIn: [window.location.origin],
                    redirectSignOut: [window.location.origin],
                    responseType: "code",
                    scopes: ["openid"]
                }
            }
        }
    }
}

export default config;