import { Box, Button, Grid, Modal, SpaceBetween } from '@cloudscape-design/components';
import React, { useState } from 'react';
import {
    ContentPageScreen,
    FavoritesScreen,
    FeedbackScreen,
    FooterScreen,
    NavigationScreen,
    PersistentUrlScreen,
    QuickSearchScreen,
    RecentlyViewedScreen,
    SearchResultsScreen,
    UserPreferencesScreen,
    WikiScreen,
} from './screens';
import { useTranslate } from 'src/i18n/useTranslate';
import { useWelcomeModal } from '../providers/WelcomeModalProvider';
import { NOTIFICATIONS, useNotifications } from '../providers/NotificationsProvider';
import { useNavigate } from 'react-router';

export const WelcomeGruModal = () => {
    const t = useTranslate();
    const navigate = useNavigate();
    const { welcomeGruOpen, setWelcomeGruOpen } = useWelcomeModal();
    const { publishNotification } = useNotifications();

    const [page, setPage] = useState(1);
    const SCREENS = [
        UserPreferencesScreen,
        NavigationScreen,
        QuickSearchScreen,
        SearchResultsScreen,
        ContentPageScreen,
        PersistentUrlScreen,
        FeedbackScreen,
        FavoritesScreen,
        RecentlyViewedScreen,
        FooterScreen,
        WikiScreen,
    ]; // order of screens are important
    const CurrentScreen = SCREENS[page - 1];

    const handleDismiss = () => {
        setWelcomeGruOpen(false);
        setPage(1);

        publishNotification({
            id: NOTIFICATIONS.WELCOME_GRU_INFO,
            type: "info",
            content: (
                <>
                    {t('welcome_gru_info_notification', "The GRU Tutorial is always available at the bottom of the page or in the info bar.")}
                </>
            )
        });
    }

    const handlePageChange = (page: number) => {
        if (page > SCREENS.length) {
            handleDismiss();
        } else {
            setPage(page);
        }
    }

    return (
        <Modal
            onDismiss={handleDismiss}
            visible={welcomeGruOpen}
            size='large'
            data-test-id='welcome-gru-modal'
        >
            <SpaceBetween size='l'>
                <CurrentScreen />
                <Grid
                    gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
                >
                    <div className={`${page === 1 ? 'display-none' : 'flex-row justify-content-center'}`} >
                        <Button data-testid="welcome-gru-back-button" onClick={() => handlePageChange(page - 1)}>{t("welcome_gru_back_button", "Back")}</Button>
                    </div>
                    <div className='flex-row justify-content-center' >
                        <Box variant='p' >{t("welcome_gru_page_count", `${page} out of ${SCREENS.length}`, { currentPage: page, pageTotal: SCREENS.length })}</Box>
                    </div>
                    <div className='flex-row justify-content-center' >
                        <Button data-testid="welcome-gru-next-button" variant='primary' onClick={() => handlePageChange(page + 1)}>{page === 1 ? t("welcome_gru_start_button", "Start") : page === SCREENS.length ? t("close_button", "Close") : t("annotation-context-next", "Next")}</Button>
                    </div>
                </Grid>
                <Box textAlign='center' variant='p'><Button variant='inline-link' href="https://broadcast.amazon.com/videos/1278680" target="_blank">{t('welcome_video', 'Welcome Video')}</Button></Box>
                {page !== SCREENS.length && <Box textAlign='center' variant='p'>{t("welcome_gru_not_interested", "Not interested?")}<Button data-testid="skip-tour-button" variant='inline-link' onClick={() => handlePageChange(SCREENS.length)} data-welcome-modal-skip-tour>{t("welcome_gru_skip_tour", "Skip Tour")}</Button></Box>}

            </SpaceBetween>
        </Modal>
    );
}