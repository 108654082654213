import ExpandableSection from "@cloudscape-design/components/expandable-section";
import Cards from "@cloudscape-design/components/cards";
import { Box, Button, ButtonDropdown, Link, TextContent } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import { useFavorites } from "./providers/FavoritesProvider";

export interface Item {
    title: string
    path?: string
    description: string
    image: string
    documentId?: number
    dateUpdated?: string
    isDynamic?: boolean
    versionId?: number
}

interface ExpandableContainerType {
    items: Item[]
    loading?: boolean
    expanded: boolean
    headerText: string
    selection: string | null
    handleExpandChange: () => void
    handleItemSelection: ({path, documentId, externalLinkUrl, isDynamic, dateUpdated} : {path?: string, documentId?: number, externalLinkUrl?: string, isDynamic?: boolean, dateUpdated?: string}) => void
}

const ExpandableContainer = ({ loading = false, items = [], headerText = "", selection, expanded, handleExpandChange, handleItemSelection }: ExpandableContainerType) => {
    const t = useTranslate();
    const { addFavorite, deleteFavorite, isFavorite } = useFavorites();

    return (
        <ExpandableSection
            variant="container"
            headerText={headerText + (selection ? ": " + selection : "")}
            data-testid="expandable-section"
            expanded={expanded}
            onChange={handleExpandChange}
        >
            {
                items.length
                ?
                <Cards
                    data-list-page-card-container
                    ariaLabels={{
                        itemSelectionLabel: (e, t) => `select ${t.title}`,
                        selectionGroupLabel: "Item selection"
                    }}
                    cardDefinition={{
                        header: (item: Item)  => {
                            return (
                                <div data-list-page-card data-testid="list-page-card">
                                    <TextContent>
                                        <Button onClick={() => handleItemSelection(item)} data-card-v1-header-text>
                                            <h3>
                                                {item.title}
                                            </h3>
                                        </Button>
                                    </TextContent>
                                    {item.documentId && (
                                        <ButtonDropdown
                                            onItemClick={() => {
                                                if(item.documentId) {
                                                    isFavorite(item.documentId) ? deleteFavorite(item.documentId) : addFavorite(item.documentId);
                                                }
                                            }}
                                            data-list-page-card-dropdown-container
                                            data-testid={"favorite-dropdown"}
                                            items={[
                                                { 
                                                    id: item.documentId && isFavorite(item.documentId) ? "favorite" : "not-favorite", 
                                                    text: item.documentId && isFavorite(item.documentId) ? t('remove-from-favorites', "Remove from Favorites") : t('add-to-favorites', "Add to Favorites"),
                                                    iconName: "heart" 
                                                },
                                            ]}
                                            ariaLabel="Control instance"
                                            variant="inline-icon" />
                                    )}
                                </div>
                            )
                        },
                        sections: [
                            {
                                id: "description",
                                content: item => item.description
                            },
                            {
                                id: "image",
                                content: item => item.image && (<Button data-card-image-container onClick={() => handleItemSelection(item)}><img src={item.image} style={{ width: "100%", height: "auto", margin: 'auto', maxHeight: '250px', objectFit: 'contain' }} /></Button>)
                            }
                        ]
                    }}
                    cardsPerRow={[
                        { cards: 1 },
                        { minWidth: 500, cards: 2 },
                        { minWidth: 800, cards: 3 },
                        { minWidth: 1200, cards: 4 },
                    ]}
                    items={items}
                    loading={loading}
                    loadingText={`${t('loading', 'Loading')}...`}
                    selectionType="single"
                    trackBy="title"
                    data-testid="expandable-cards"
                    data-cards-style
                />
                :
                <Box variant="h3" textAlign="center" >{t('navigation_no_documents_to_show', "No Documents to show.")}</Box>
            }
        </ExpandableSection>
    )
}

export default ExpandableContainer;