import { Box, SpaceBetween } from "@cloudscape-design/components";
import { useTranslate } from "src/i18n/useTranslate";
import RecentlyViewedImage from "src/images/tutorial-recently-viewed.jpg";

export const RecentlyViewedScreen = () => {
  const t = useTranslate();
  return (
    <SpaceBetween data-testid="welcome-gru-recently-viewed-screen" size="l">
      <div className="flex-row justify-content-center">
        <img src={RecentlyViewedImage} height={300} />
      </div>
      <Box variant="p">
        {t(
          "welcome_gru_recently_viewed_description",
          "After visiting a content page, you will see it under <b>Recently Viewed</b> on the landing page. Select View All to see the full list of all recently viewed pages.",
          { b: (chunk: string) => <strong key={chunk}>{chunk}</strong> }
        )}
      </Box>
    </SpaceBetween>
  );
};
