import React, { useState } from "react";
import Box from '@cloudscape-design/components/box';
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import { useTranslate } from "src/i18n/useTranslate";
import { useNavigate } from "react-router";
import { SearchAutoSuggest } from "./SearchAutoSuggest";

interface SearchProps {
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

const Search = ({setHasOpenedInNewTab, }: SearchProps) => {
    const t = useTranslate();
    const [searchInput, setSearchInput] = useState("");
    const navigate = useNavigate();

    return (
        <div className="search-box">
            <SpaceBetween size="m" alignItems="center">
                <Box variant="h2" textAlign="center" >{t('looking_for_label', 'Search Global Robotics University')}</Box>
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                    <div className="search-auto-suggest">
                        <SearchAutoSuggest
                            value={searchInput}
                            setValue={setSearchInput}
                            setHasOpenedInNewTab={setHasOpenedInNewTab}
                            placeholder={t('search_placeholder_text_type_a_keyword', 'Type a keyword to search GRU')}
                        />
                    </div>
                    <Button
                        variant="primary"
                        onClick={() => {
                            navigate(`/search?q=${searchInput}`)
                        }}
                    >
                        {t('search_button', 'Search')}
                    </Button>
                </SpaceBetween>
            </SpaceBetween>
        </div>
    )
}

export default Search;