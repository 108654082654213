import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import { Stage, getStage } from './helpers';

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
    const stage = getStage();

    if (stage == Stage.TEST) {
        return new KatalMetricsDriverConsoleLogJson();
    } else if (stage == Stage.BETA || stage == Stage.GAMMA) {
        return new KatalMetricsDriverSushi.Builder()
            .withDomainRealm('test', 'USAmazon')
            .withErrorHandler(metricsConsoleErrorHandler)
            .build();
    } else if (stage == Stage.PROD) {
        return new KatalMetricsDriverSushi.Builder()
            .withDomainRealm('prod', 'USAmazon')
            .withErrorHandler(metricsConsoleErrorHandler)
            .build();
    } else {
        return new KatalMetricsDriverConsoleLogJson();
    }
};

const makePublisher = (): KatalMetrics.Publisher => {

    const metricsDriver = makeMetricsDriver();
    const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite('GlobalRoboticsUniversity')
        .withServiceName('GlobalRoboticsUniversity')
        .build();
    return new KatalMetrics.Publisher(
        metricsDriver,
        metricsConsoleErrorHandler,
        initialMetricsContext);
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;