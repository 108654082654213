import { Autosuggest, AutosuggestProps } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { openExternalUrl } from "src/helpers";
import { useApi } from "src/hooks/useApi";
import { useTranslate } from "src/i18n/useTranslate";
import { GetAutoSuggestApiType } from "src/types/useApi";
import { useRecentlyViewed } from "./providers/RecentlyViewedProvider";
import { AutoSuggestion } from "src/types/autoSuggest";

interface SearchAutoSuggestProps {
    value: string;
    setValue: (value: string) => void;
    placeholder: string;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

export const SearchAutoSuggest = ({ value, setValue, placeholder, setHasOpenedInNewTab, }: SearchAutoSuggestProps) => {
    const [options, setOptions] = useState<AutosuggestProps.Options>([]);

    const navigate = useNavigate();
    const t = useTranslate();
    const { addRecentlyViewed } = useRecentlyViewed();

    const { makeRequest, loading, data, currentRequest }: GetAutoSuggestApiType = useApi({
        method: 'GET',
        url: "/autosuggest"
    });

    const createOptionValue = ({documentId, lcmsXmlGUID, externalUrl}: AutoSuggestion) => {
        return `${documentId}~${lcmsXmlGUID || externalUrl}`
    };

    useEffect(() => {
        const query = value.trim();

        if (query.length < 5) {
            if (options.length) {
                setOptions([]);
            }
        } else if (!loading && currentRequest?.params?.query !== query) {
            makeRequest({
                params: { query }
            })
        }

    }, [loading, value]);

    useEffect(() => {
        if (data) {
            const filteredData = data.slice(0, 10);
            setOptions(filteredData.map(suggestion => {
                return {
                    label: suggestion.name,
                    value: createOptionValue(suggestion)
                }
            }))
        }
    }, [data]);

    const handleSelection = (value: string) => {
        const suggestion = data?.find(suggestion => createOptionValue(suggestion) === value);

        if (!suggestion) return;

        if (suggestion.externalUrl) {
            addRecentlyViewed(suggestion.documentId);
            openExternalUrl(suggestion.externalUrl);
        } else if (suggestion.documentId) {
            let path = "/" + suggestion.documentId;

            if (suggestion.lcmsXmlGUID) {
                path += "#/" + suggestion.lcmsXmlGUID;
            }
            setHasOpenedInNewTab(false);
            navigate(path);
        }
    }

    const notEnoughCharactersText = t("search-auto-suggest-not-enough-characters", "Use a longer search term to get recommendations", { numberOfCharactersNeeded: 5 });
    const noSuggestionsText = t("search-auto-suggest-no-suggestions", "No suggestions to show");

    return (
        <Autosuggest
            value={value}
            placeholder={placeholder}
            filteringType="manual"
            options={options}
            onSelect={({ detail }) => handleSelection(detail.value)}
            onChange={({ detail }) => {
                if (data?.find(suggestion => createOptionValue(suggestion) === detail.value)) return;
                setValue(detail.value);
            }}
            onKeyDown={({ detail }) => {
                if (detail.key === 'Enter') {
                    navigate(`/search?q=${value}`)
                }
            }}
            empty={notEnoughCharactersText}
            loadingText={`${t('loading', 'Loading')}...`}
            statusType={loading ? "loading" : "finished"}
            enteredTextLabel={() => value.trim().length < 5 ? notEnoughCharactersText : !loading && !options.length ? noSuggestionsText : ''}
            data-entered-text-label-show={!(value.trim().length < 5 || (!loading && !options.length)) ? "false" : "true"}
        />
    )
};