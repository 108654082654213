import React, { useContext, useEffect } from "react";
import { useApi } from "src/hooks/useApi";
import { RecentlyViewed } from "src/types/recentlyViewed";
import { GetRecentlyViewedApiType, PostRecentlyViewedApiType } from "src/types/useApi";
import { useAuth } from "./AuthProvider";


export interface RecentlyViewedContextType {
    recentlyViewed: RecentlyViewed[];
    addRecentlyViewed: (id: string | number) => void;
}

export const RecentlyViewedContext = React.createContext<RecentlyViewedContextType>({
    recentlyViewed: [],
    addRecentlyViewed: () => { },
})

export const useRecentlyViewed = () => useContext(RecentlyViewedContext)

interface RecentlyViewedProviderProps {
    children: React.ReactNode;
}

export const RecentlyViewedProvider = ({ children }: RecentlyViewedProviderProps) => {

    const { isPrivacyRestricted } = useAuth();

    const { makeRequest: getAllRecentlyViewed, data: allRecentlyViewedData }: GetRecentlyViewedApiType = useApi({
        url: `user-profile/recently-viewed`,
        method: 'GET',
    });

    const { makeRequest: makeAddRecentlyViewedRequest, data: addAddRecentlyViewedData }: PostRecentlyViewedApiType = useApi({
        url: `user-profile/recently-viewed`,
        method: 'POST',
    });

    useEffect(() => {
        if (isPrivacyRestricted()) return;
        getAllRecentlyViewed();
    }, []);

    useEffect(() => {
        if (addAddRecentlyViewedData) {
            getAllRecentlyViewed();
        }

    }, [addAddRecentlyViewedData])

    const addRecentlyViewed = (id?: string | number) => {
        if (!id || isPrivacyRestricted()) return;
        makeAddRecentlyViewedRequest({ data: { id } });
    };

    const value: RecentlyViewedContextType = {
        recentlyViewed: allRecentlyViewedData || [],
        addRecentlyViewed,
    }

    return <RecentlyViewedContext.Provider value={value}>{children}</RecentlyViewedContext.Provider>
}