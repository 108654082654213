import React from 'react';
import { Route, Routes } from "react-router-dom";
import FavoritesPage from 'src/pages/favorites';
import Home from 'src/pages/home-page';
import LearningArtifactDetailPage from 'src/pages/learning-artifact-detail-page';
import NotFoundPage from "src/pages/not-found-page";
import { useNotifications } from './providers/NotificationsProvider';
import { Flashbar } from '@cloudscape-design/components';
import SearchPage from 'src/pages/search-page';
import RecentlyViewed from 'src/pages/recently-viewed';
import { InfoBanner } from './InfoBanner';

interface AppRoutesProps{
    hasOpenedInNewTab: boolean;
    setHasOpenedInNewTab: (hasOpenedInNewTab: boolean) => void;
}

export const AppRoutes = ({
    hasOpenedInNewTab,
    setHasOpenedInNewTab,
}: AppRoutesProps) => {

    const { notifications } = useNotifications();

    return (
        <>
            <InfoBanner />
            {notifications.length > 0 && (
                <Flashbar items={notifications} data-notifications-flashbar />
            )}
            <Routes>
                <Route path="/" element={<Home setHasOpenedInNewTab={setHasOpenedInNewTab} />} />
                <Route path="/not-found" element={<NotFoundPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/favorites" element={<FavoritesPage />} />
                <Route path="/recent" element={<RecentlyViewed />} />
                <Route path="/:artifactId" element={<LearningArtifactDetailPage hasOpenedInNewTab={hasOpenedInNewTab} setHasOpenedInNewTab={setHasOpenedInNewTab} />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </>
    )
}