import React from 'react'
import { useBundle } from '@amzn/react-arb-tools'
import { GRU_MESSAGE_BUNDLE_NAME } from 'src/constants'

export type UseTranslate = (stringKey: string, defaultValue: string, args?: {[key: string]: any}) => string


const  useTranslate = (): UseTranslate => {
  const [bundle, isBundleLoading] = useBundle(GRU_MESSAGE_BUNDLE_NAME);

  return React.useCallback(
    (stringKey: string, defaultValue = "", args?: unknown) => {
      if (isBundleLoading) return defaultValue;
      let message = defaultValue;
      try {
        message = args ? bundle.formatMessage(stringKey, args) : bundle.getMessage(stringKey)
      } catch (e) {
        console.log(`Unable to find pather translation for key: ${stringKey}`)
      }
      return message;
    },
    [isBundleLoading, bundle],
  );
}

export { useTranslate };